import { FooterWrapper } from "./FooterStyle";

const Footer = () => {
  return (
    <FooterWrapper>
      <span>جميع الحقوق محفوظة لشركة احجزلي</span>
    </FooterWrapper>
  );
};

export default Footer;
