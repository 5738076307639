import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Flex, Text, Alert, AlertIcon, Link, Box, Button } from '@chakra-ui/react';

import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import NotificationsList from './NotificationsList';

import { getNotifications, updateNotifications } from '../../../store/notifications/notificationsSlice';

import { NotificationsWrapper } from './NotificationsStyle';

import theme from '../../global/theme';

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [query] = useState("");
  const defaultFilter = {};

  if (searchParams.get("type")) {
    defaultFilter["type"] = searchParams.get("type");
  }

  const [filter] = useState(JSON.stringify(defaultFilter));

  useEffect(() => {
    dispatch(getNotifications({ page, query, filter }));
  }, [dispatch, page, query, filter]);


  return (
    <NotificationsWrapper>

      <Breadcrumbs currentPage={t('pages.notifications.notifications')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {notifications.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {notifications.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <Flex mb={4} flexWrap="wrap">
        <Link
          href="/notifications" marginInline={2} mb={4}
          p={3} borderRadius={6}
          bg={!searchParams.get("type") ? theme.primary : theme.border}
          color={!searchParams.get("type") ? theme.light : theme.dark}
        >
          جميع الاشعارات
        </Link>
        <Link
          href="/notifications?type=order_note" marginInline={2} mb={4}
          p={3} borderRadius={6}
          bg={searchParams.get("type") === "order_note" ? theme.primary : theme.border}
          color={searchParams.get("type") === "order_note" ? theme.light : theme.dark}
        >
          ملاحظات الطلب
        </Link>
        <Link
          href="/notifications?type=request" marginInline={2} mb={4}
          p={3} borderRadius={6}
          bg={searchParams.get("type") === "request" ? theme.primary : theme.border}
          color={searchParams.get("type") === "request" ? theme.light : theme.dark}
        >
          طلبات السحب
        </Link>
        <Link
          href="/notifications?type=order_status" marginInline={2} mb={4}
          p={3} borderRadius={6}
          bg={searchParams.get("type") === "order_status" ? theme.primary : theme.border}
          color={searchParams.get("type") === "order_status" ? theme.light : theme.dark}
        >
          تغيير حالة الطلب
        </Link>
      </Flex>

      <Box mb={4}>
        <Button
          type="button" bg="red.700" color="white" _hover={{ bg: "red.500" }}
          onClick={() => dispatch(updateNotifications()).unwrap().then(_ => {
            window.location.reload();
          })}
        >
          تمييز الكل كمقروء
        </Button>
      </Box>

      {notifications.data?.itemsCount > 0 ? (
        <NotificationsList
          data={notifications.data.data}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} height={200} alignItems="center"
          boxShadow={theme.shadow}
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.notifications.no_notifications')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={notifications.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </NotificationsWrapper>
  )
}

export default Notifications