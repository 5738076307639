import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Select,
  useToast,
  Alert,
  AlertIcon,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";

import Search from "../../shared/search/Search";
import { getPriceList } from "../../../store/priceList/priceListSlice";
import { createOrder } from "../../../store/orders/ordersSlice";
import { cities } from "../../../utilities/places";
import { convertArNumbersToEnNumbers } from "../../../utilities/order";
import theme from "../../global/theme";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import { removeFromCart, emptyCart } from "../../../store/cart/cartSlice";
import { CartWrapper } from "./CartStyle";
import { useTranslation } from "react-i18next";
const CartDrawer = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const orders = useSelector((state) => state.orders);
  const priceList = useSelector((state) => state.priceList);
  const navigate = useNavigate();

  const btnRef = React.useRef();

  const toast = useToast({ duration: 2000, position: "top", status: "error" });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { items: cart.items },
  });

  watch(["items", "shipping_governorate", "total"]);
  useEffect(() => {
    setValue("items", cart.items);
  }, [cart?.items]);

  const [priceListPage, setPriceListPage] = useState(1);
  const [priceListQuery, setPriceListQuery] = useState("");
  const [priceListFilter] = useState(JSON.stringify({ is_active: true }));

  useEffect(() => {
    dispatch(
      getPriceList({
        page: priceListPage,
        size: 50,
        query: priceListQuery,
        filter: priceListFilter,
      })
    );
  }, [dispatch, priceListPage, priceListQuery, priceListFilter]);

  const getTotalProductsPrice = () => {
    let total = 0;
    for (let i = 0; i < getValues("items").length; i++) {
      total +=
        (getValues("items")[i].product?.sale_price || 0) *
        (+getValues("items")[i].qty || 0);
    }
    return total;
  };
  const getTotalItems = () => {
    let totalItems = 0;
    for (let i = 0; i < getValues("items").length; i++) {
      totalItems += +getValues("items")[i].qty || 0;
    }
    return totalItems;
  };
  const getCommission = () => {
    const productsPrice = getTotalProductsPrice();
    const shipping = +getValues("shipping_governorate")?.price || 0;
    const total = +getValues("total") || 0;
    const commssion = total - (productsPrice + shipping);
    return commssion;
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Breadcrumbs
              currentPage={"السلة"}
              pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
            />
          </DrawerHeader>

          <DrawerBody>
            <CartWrapper>
              <form
                onSubmit={handleSubmit((values) => {
                  const items = values.items;

                  // global validation
                  if (items.length === 0)
                    return toast({
                      description: "من فضلك اختر على الاقل منتج واحد!",
                    });
                  if (!values.shipping_governorate)
                    return toast({ description: "من فضلك اختر محافظة الشحن" });
                  if (isNaN(getCommission()) || getCommission() < 0)
                    return toast({ description: "ادخل الاجمالي بشكل صحيح" });

                  values["shipping_governorate"] =
                    values.shipping_governorate?._id;
                  values["commission"] = getCommission();
                  values["client_phone1"] = convertArNumbersToEnNumbers(
                    values["client_phone1"]
                  );
                  values["client_phone2"] = convertArNumbersToEnNumbers(
                    values["client_phone2"]
                  );

                  for (let i = 0; i < items.length; i++) {
                    items[i]["product"] = items[i]["product"]?._id;
                    items[i]["property"] = items[i]["property"]?._id;
                  }

                  dispatch(createOrder(values))
                    .unwrap()
                    .then((_) => {
                      dispatch(emptyCart());
                      toast({
                        status: "success",
                        description: "تم اضافة الطلب بنجاح",
                      });
                      setValue("client_name", "");
                      setValue("client_phone1", "");
                      setValue("client_phone2", "");
                      setValue("client_address", "");
                      setValue("shipping_governorate", "");
                      setValue("city", "");
                      setValue("page_name", "");
                      setValue("total", "");
                      navigate("/orders?page=1&size=50&query=");
                    });
                })}
              >
                {orders.errors.length > 0 && (
                  <Alert status="error" variant="left-accent" marginBottom={8}>
                    <AlertIcon />
                    {orders.errors?.map((error, index) => (
                      <Text key={index}>{error?.msg}</Text>
                    ))}
                  </Alert>
                )}
                {cart.items.length > 0 ? (
                  <>
                    <Table
                      variant="striped"
                      colorScheme="gray"
                      size="sm"
                      className="bg-white rounded-lg shadow-sm"
                    >
                      <Thead>
                        <Tr>
                          <Th className="text-sm text-gray-600">المنتج</Th>
                          <Th className="text-sm text-gray-600">الخاصية</Th>
                          <Th className="text-sm text-gray-600">الكمية</Th>
                          <Th className="text-sm text-gray-600">
                            سعر القطعة الواحده
                          </Th>
                          <Th className="text-sm text-gray-600">الإجراء</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cart.items.map((item, index) => (
                          <Tr key={index} className="text-xs text-gray-900">
                            <Td className="px-4 py-2">
                              <Box className="font-medium">
                                {item.product.name}
                              </Box>
                            </Td>
                            <Td className="px-4 py-2">
                              <Box className="font-medium">
                                {item.property.key}
                              </Box>
                            </Td>
                            <Td className="px-4 py-2">
                              <Input
                                bg="gray.100"
                                type="number"
                                className="w-20 text-sm focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
                                placeholder="3"
                                defaultValue={item.qty}
                                {...register(`items.${index}.qty`, {
                                  required: `${t("general.required")}`,
                                  min: 1,
                                  max: 6,
                                })}
                              />
                            </Td>
                            <Td className="px-4 py-2">
                              <Box className="font-medium">
                                {item.product.sale_price} جم
                              </Box>
                            </Td>
                            <Td className="px-4 py-2">
                              <Button
                                bg={theme.error}
                                className="px-4 py-1 text-xs font-semibold text-white transition-colors duration-300 bg-red-600 rounded-full hover:bg-red-600"
                                onClick={() =>
                                  dispatch(
                                    removeFromCart({
                                      propertyId: item.property._id,
                                    })
                                  )
                                }
                              >
                                حذف
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>

                    <Box mt={6} textAlign="center">
                      <Button
                        bg={theme.error}
                        color={theme.light}
                        type="button"
                        onClick={() => dispatch(emptyCart())}
                      >
                        تفريغ السلة
                      </Button>
                    </Box>
                    <SimpleGrid
                      columns={{ sm: 1, md: 2, xl: 3 }}
                      spacing={6}
                      marginBlock={8}
                    >
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          اسم العميل بالكامل
                        </FormLabel>
                        <Input
                          type="text"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.client_name")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("client_name", {
                            required: `${t("validation.required")}`,
                            maxLength: {
                              value: 40,
                              message: `${t("validation.max_length")} 40`,
                            },
                          })}
                        />
                        {errors.client_name?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.client_name.message}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          {t("pages.orders.client_phone1")}
                        </FormLabel>
                        <Input
                          type="tel"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.client_phone1")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("client_phone1", {
                            required: `${t("validation.required")}`,
                            minLength: {
                              value: 11,
                              message: `${t("validation.min_length")} 11`,
                            },
                            maxLength: {
                              value: 11,
                              message: `${t("validation.max_length")} 11`,
                            },
                          })}
                        />
                        {errors.client_phone1?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.client_phone1.message}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          {t("pages.orders.client_phone2")} - اختياري
                        </FormLabel>
                        <Input
                          type="tel"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.client_phone2")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("client_phone2", {
                            minLength: {
                              value: 11,
                              message: `${t("validation.min_length")} 11`,
                            },
                            maxLength: {
                              value: 11,
                              message: `${t("validation.max_length")} 11`,
                            },
                          })}
                        />
                        {errors.client_phone2?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.client_phone2.message}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          عنوان العميل تفصيلي
                        </FormLabel>
                        <Input
                          type="text"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.client_address")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("client_address", {
                            required: `${t("validation.required")}`,
                          })}
                        />
                        {errors.client_address?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.client_address.message}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          {t("pages.orders.shipping_governorate")}
                        </FormLabel>
                        <Search
                          allowSearch={true}
                          allowPagination={false}
                          color={theme.dark}
                          bg={theme.border}
                          page={priceListPage}
                          itemsCount={priceList.data?.itemsCount}
                          data={priceList.data?.data}
                          type="fromGovernorate"
                          prop="governorate"
                          currentElement={getValues("shipping_governorate")}
                          handleChangePage={(page) => {
                            setPriceListPage(page);
                          }}
                          handleChangeQuery={(query) => {
                            setPriceListQuery(query);
                          }}
                          handleSelected={(item) => {
                            setValue("shipping_governorate", item);
                            setValue("city", "");
                          }}
                        />
                      </FormControl>

                      {getValues("shipping_governorate") && (
                        <FormControl>
                          <FormLabel
                            fontWeight="bold"
                            textTransform="capitalize"
                            color={theme.dark}
                          >
                            {t("pages.orders.city")}
                          </FormLabel>
                          <Select
                            bg={theme.border}
                            color={theme.dark}
                            border="none"
                            borderRadius={4}
                            placeholder={t("pages.orders.city")}
                            _placeholder={{ textTransform: "capitalize" }}
                            {...register("city", {
                              required: `${t("validation.required")}`,
                            })}
                          >
                            {cities
                              .filter(
                                (f) =>
                                  +f.governorate_id ===
                                  getValues("shipping_governorate")?.governorate
                              )
                              .map((city) => (
                                <option
                                  style={{ color: "#222222" }}
                                  key={city.id}
                                  value={city.id}
                                >
                                  {city.city_name_ar}
                                </option>
                              ))}
                          </Select>
                          {errors.city?.message && (
                            <Text color="red.600" marginTop={2}>
                              {errors.city.message}
                            </Text>
                          )}
                        </FormControl>
                      )}

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          {t("pages.orders.page_name")}
                        </FormLabel>
                        <Input
                          type="text"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.page_name")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("page_name")}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          textTransform="capitalize"
                          color={theme.dark}
                        >
                          {t("pages.orders.total")}
                        </FormLabel>
                        <Input
                          type="number"
                          bg={theme.border}
                          color={theme.dark}
                          border="none"
                          borderRadius={4}
                          placeholder={t("pages.orders.total")}
                          _placeholder={{ textTransform: "capitalize" }}
                          {...register("total", {
                            required: `${t("validation.required")}`,
                            min: 1,
                          })}
                        />
                        {errors.total?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.total.message}
                          </Text>
                        )}
                      </FormControl>
                    </SimpleGrid>

                    <FormControl mt={6}>
                      <FormLabel
                        fontWeight="bold"
                        textTransform="capitalize"
                        color={theme.dark}
                      >
                        {t("pages.orders.note")}
                      </FormLabel>
                      <Textarea
                        bg={theme.border}
                        color={theme.dark}
                        border="none"
                        placeholder={t("pages.orders.note")}
                        _placeholder={{ textTransform: "capitalize" }}
                        {...register("note", {
                          maxLength: {
                            value: 150,
                            message: `${t("validation.max_length")} 150`,
                          },
                        })}
                      />
                      {errors.note?.message && (
                        <Text color="red.600" marginTop={2}>
                          {errors.note.message}
                        </Text>
                      )}
                    </FormControl>

                    <Box
                      color={theme.dark}
                      mt={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Box display="flex">
                        <UnorderedList
                          m="0"
                          listStyleType="none"
                          textAlign="end"
                          textTransform="capitalize"
                        >
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {getTotalItems() || "-"}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {getTotalProductsPrice() || "-"}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {getValues("shipping_governorate")?.price ?? "-"}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {getCommission()}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {getValues("total") || "-"}
                          </ListItem>
                        </UnorderedList>
                        <UnorderedList
                          m="0"
                          listStyleType="none"
                          textAlign="end"
                          textTransform="capitalize"
                        >
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {t("pages.orders.quantity")}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {t("pages.orders.products_price")}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {t("pages.orders.shipping")}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {t("pages.orders.commission")}
                          </ListItem>
                          <ListItem
                            border={`1px solid ${theme.border}`}
                            paddingBlock={2}
                            paddingInline={6}
                          >
                            {t("pages.orders.total")}
                          </ListItem>
                        </UnorderedList>
                      </Box>
                    </Box>

                    <Button
                      type="submit"
                      rightIcon={<FiIcons.FiSave />}
                      color={theme.light}
                      bg="#FC6736"
                      paddingInline={4}
                      paddingBlock={2}
                      height="auto"
                      textTransform="capitalize"
                      isLoading={orders.isLoading}
                      _hover={{ background: "green" }}
                    >
                      إكمال الطلب
                    </Button>
                  </>
                ) : (
                  <Flex
                    textAlign="center"
                    bg={theme.light}
                    boxShadow={theme.shadow}
                    height={200}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={16}
                  >
                    <Text
                      fontSize={18}
                      textTransform="capitalize"
                      color="gray.300"
                      fontWeight="bold"
                    >
                      لا يوجد منتجات في السلة
                    </Text>
                  </Flex>
                )}
              </form>
            </CartWrapper>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CartDrawer;
