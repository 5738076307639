import React from "react";
import ReactPaginate from "react-paginate";
import { PaginationWrapper } from "./PaginationStyle";

const Pagination = ({ page, size = 10, itemsCount, onChange }) => {
  const pageCount = Math.ceil(itemsCount / size);

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    onChange(selectedPage);
  };

  return (
    <PaginationWrapper>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={page - 1}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
