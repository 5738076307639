import styled from "styled-components";

export const PaginationWrapper = styled.div`
  padding-top: 1.5rem;

  .pagination {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .pagination li {
    margin-inline-end: 0.5rem;
    margin-bottom: 0.5rem;

    &.active a {
      background: #0c2d57;
      color: #fff;
      cursor: default;
    }

    a {
      width: 35px;
      height: 35px;
      border-radius: 20%;
      background: #fc6736;
      color: #efecec;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .pagination li.disabled a {
    background: #ccc;
    cursor: not-allowed;
  }
`;
