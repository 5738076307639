import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Text, Input, Alert, AlertIcon, useToast } from '@chakra-ui/react';

import { validateMaxLength, validateNumeric } from "../../../utilities/validation";
import { convertArNumbersToEnNumbers } from "../../../utilities/order";
import { governorates, cities } from "../../../utilities/places";

import { createOrders } from "../../../store/orders/ordersSlice";

import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import { useTranslation } from "react-i18next";
import theme from "../../global/theme";

const ExcelOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector(state => state.orders);
  const toast = useToast({ position: "top", status: "error" });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = jsonData[0];

      // Validate the header keys of the excel
      const allowHeaders = [
        "client_name",
        "client_phone1",
        "client_phone2",
        "client_address",
        "shipping_governorate",
        "city",
        "page_name",
        "total",
        "products_codes",
        "products_properties",
        "products_qty",
        "note"
      ];
      for (let i = 0; i < allowHeaders.length; i++) {
        const allowHeaderKey = allowHeaders[i];
        if (!headers.includes(allowHeaderKey)) {
          return toast({ description: `${allowHeaderKey} doest not exists` });
        }
      }

      // convert orders to array of objects
      const rows = jsonData.slice(1);
      const orders = rows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      // validate order length
      if (orders.length > 300) return toast({ description: "عدد الاوردرات المسموح في الشيت الواحد ٣٠٠ اوردر فقط" });

      // validate orders data
      for (let i = 0; i < orders.length; i++) {
        const orderData = orders[i];

        // validate client name
        const validateClientName = validateMaxLength(orderData.client_name, 50);
        if (!validateClientName) return toast({ description: `اسم العميل في الاوردر رقم ${i + 1} غير صحيح` });

        // validate client phone1
        const validateClientPhone1MaxLength = validateMaxLength(orderData.client_phone1, 11);
        if (!validateClientPhone1MaxLength) return toast({ description: `رقم العميل في الاوردر رقم ${i + 1} غير صحيح` });
        orderData.client_phone1 = convertArNumbersToEnNumbers(String(orderData.client_phone1));

        // validate client phone2
        const validateClientPhone2MaxLength = validateMaxLength(orderData.client_phone2, 11);
        if (!validateClientPhone2MaxLength) return toast({ description: `رقم العميل الثاني في الاوردر رقم ${i + 1} غير صحيح` });
        orderData.client_phone2 = convertArNumbersToEnNumbers(String(orderData.client_phone2));

        // validate total
        const validateTotal = validateNumeric(orderData.total);
        if (!validateTotal) return toast({ description: `الاجمالي في رقم الاوردر ${i + 1} غير صحيح` });

        // validate shippingGovernorate
        const validateShippingGovernorate = governorates.find(el => el.governorate_name_ar === orderData.shipping_governorate);
        if (!validateShippingGovernorate) return toast({ description: "محافظة الشحن غير موجوده" });
        orderData.shipping_governorate = validateShippingGovernorate.id;

        // validate city
        const validateCity = cities.find(el => el.governorate_id === validateShippingGovernorate.id && el.city_name_ar === orderData.city);
        if (!validateCity) {
          const findFirstCity = cities.find(el => el.governorate_id === validateShippingGovernorate.id);
          console.log(findFirstCity);
          orderData.city = findFirstCity.id;
        } else {
          orderData.city = validateCity.id;
        }

        let items = [];
        let productsCodes = String(orders[i].products_codes).split(",");
        let productsProperties = String(orders[i].products_properties).split(",");
        let productsQty = String(orders[i].products_qty).split(",");
        for (let j = 0; j < productsCodes.length; j++) {
          items.push({
            barcode: productsCodes[j],
            property: productsProperties[j],
            qty: productsQty[j]
          });
        }
        orderData.items = items;
      }

      console.log(orders);

      dispatch(createOrders({ orders })).unwrap().then(res => {
        document.querySelectorAll("button").forEach(el => {
          el.remove();
        });
        toast({ status: "success", description: "orders created successfully" });
        window.location.href = "/orders?page=1&size=50&query="
      })

    };
    reader.readAsArrayBuffer(file);
  }

  return (
    <>
      <Breadcrumbs currentPage={t('pages.orders.orders')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />
      {orders.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {orders.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      <Link
        href="/files/sample_excel.xlsx" bg={theme.error} color={theme.light} borderRadius="4px"
        display="inline-block" paddingInline="1rem" paddingBlock=".5rem" mb={6}
      >
        تحميل ملف اكسل
      </Link>

      <Text mb={3}>اضغط لرفع اوردرات من شيت اكسل</Text>
      <Input
        type="file"
        onChange={handleFileUpload}
      />
    </>
  )
}

export default ExcelOrders