import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getHistory = createAsyncThunk(
  "history/getHistory",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = "{}" } = args;
      const { data } = await axios.get(
        `/api/get_history?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: 'something went wrong' }] }
          : errors.response.data
      );
    }
  }
);

const historySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getHistory.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      });
  },
});

export default historySlice.reducer;
