import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import theme from "../../global/theme";
import {
  getNotifications,
  updateNotifications,
} from "../../../store/notifications/notificationsSlice";
import NotificationsList from "../../modules/notifications/NotificationsList";
import Pagination from "../../shared/pagination/Pagination";

const NotificationsDrawer = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  const [page, setPage] = useState(1);
  const [filterType, setFilterType] = useState("");

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  useEffect(() => {
    const filter = filterType ? JSON.stringify({ type: filterType }) : "{}";
    dispatch(getNotifications({ page, filter }));
  }, [dispatch, page, filterType]);

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent px={2}>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>الإشعارات</Text>
              <Box>
                <Button
                  type="button"
                  bg="red.700"
                  color="white"
                  _hover={{ bg: "red.500" }}
                  leftIcon={<FiCheckCircle />} // Add the icon here
                  onClick={() =>
                    dispatch(updateNotifications())
                      .unwrap()
                      .then(() => {
                        window.location.reload();
                      })
                  }
                >
                  الكل كمقروء
                </Button>
              </Box>
            </Flex>
          </DrawerHeader>
          <Flex mb={4} flexWrap="wrap">
            <Button
              onClick={() => handleFilterChange("")}
              marginInline={2}
              mb={4}
              p={3}
              borderRadius={6}
              bg={!filterType ? theme.primary : theme.border}
              color={!filterType ? theme.light : theme.dark}
            >
              جميع الاشعارات
            </Button>
            <Button
              onClick={() => handleFilterChange("order_note")}
              marginInline={2}
              mb={4}
              p={3}
              borderRadius={6}
              bg={filterType === "order_note" ? theme.primary : theme.border}
              color={filterType === "order_note" ? theme.light : theme.dark}
            >
              ملاحظات الطلب
            </Button>
            <Button
              onClick={() => handleFilterChange("request")}
              marginInline={2}
              mb={4}
              p={3}
              borderRadius={6}
              bg={filterType === "request" ? theme.primary : theme.border}
              color={filterType === "request" ? theme.light : theme.dark}
            >
              طلبات السحب
            </Button>
            <Button
              onClick={() => handleFilterChange("order_status")}
              marginInline={2}
              mb={4}
              p={3}
              borderRadius={6}
              bg={filterType === "order_status" ? theme.primary : theme.border}
              color={filterType === "order_status" ? theme.light : theme.dark}
            >
              تغيير حالة الطلب
            </Button>
          </Flex>

          <DrawerBody>
            {notifications.data?.itemsCount > 0 ? (
              <NotificationsList data={notifications.data?.data} />
            ) : (
              <Flex
                textAlign="center"
                bg={theme.light}
                height={200}
                alignItems="center"
                boxShadow={theme.shadow}
                justifyContent="center"
                borderRadius={16}
              >
                <Text
                  fontSize={18}
                  textTransform="capitalize"
                  color="gray.300"
                  fontWeight="bold"
                >
                  {t("pages.notifications.no_notifications")}
                </Text>
              </Flex>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Pagination
              page={page}
              itemsCount={notifications.data?.itemsCount ?? 0}
              onChange={(page) => setPage(page)}
            />
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default NotificationsDrawer;
