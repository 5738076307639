import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Box, Button } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

import Table from "../../shared/table/Table";

import theme from "../../global/theme";

const RequestsTable = ({ data, page, handleUpdate }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          {auth.user.role === "admin" ? (
            <>
              <th>الاسم</th>
              <th>النوع</th>
              <th>الكود</th>
              <th>رقم الهاتف</th>
            </>
          ) : (
            <th>رقم الهاتف</th>
          )}
          <th>{t("pages.requests.created_at")}</th>
          <th>اخر تحديث</th>
          <th>{t("pages.requests.amount")}</th>
          <th>{t("pages.requests.payment_method")}</th>
          <th>{t("pages.requests.note")}</th>
          <th>{t("pages.requests.status")}</th>
          <th>السبب</th>
          {auth.user.role === "admin" && <th>{t("general.action")}</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            {auth.user.role === "admin" ? (
              <>
                <td>{el.target?.name}</td>
                <td>{el.target?.role}</td>
                <td>{el.target?.code}</td>
                <td>{el.phone}</td>
              </>
            ) : (
              <td>{el.phone}</td>
            )}
            <td>{new Date(el.created_at).toLocaleString()}</td>
            <td>
              {el.status === "pending"
                ? "-"
                : new Date(el.updated_at).toLocaleString()}
            </td>
            <td>{el.amount}</td>
            <td>{el.payment_method}</td>
            <td>{el.note}</td>
            <td>
              <Box
                style={{
                  backgroundColor:
                    el.status === "pending"
                      ? "orange"
                      : el.status === "rejected"
                      ? theme.error
                      : "green",
                }}
                color={theme.light}
                display="inline-block"
                paddingInline={2}
                paddingBlock={1}
                borderRadius="5px"
              >
                {t(`pages.requests.${el.status}`)}
              </Box>
            </td>
            <td>{el.reason}</td>
            {auth.user.role === "admin" && (
              <td>
                {el.status === "pending" && auth.user.role === "admin" ? (
                  <Button
                    bg="transparent"
                    color="green"
                    size="xs"
                    onClick={() => handleUpdate(el)}
                  >
                    <FiIcons.FiEdit size={20} />
                  </Button>
                ) : (
                  "-"
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default RequestsTable;
