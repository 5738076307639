import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getPriceList = createAsyncThunk(
  "priceList/getPriceList",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_price_list?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const clonsePriceList = createAsyncThunk(
  "priceList/clonsePriceList",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/clonsePriceList?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createPriceList = createAsyncThunk(
  "priceList/createPriceList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_price_list", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updatePriceList = createAsyncThunk(
  "priceList/updatePriceList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_price_list/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const deletePriceList = createAsyncThunk(
  "priceList/deletePriceList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/delete_price_list/${args._id}`,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

const priceListSlice = createSlice({
  name: "priceList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPriceList.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getPriceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getPriceList.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      .addCase(clonsePriceList.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(clonsePriceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(clonsePriceList.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      .addCase(createPriceList.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createPriceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount++;
        if (state.data?.data?.length < 10) {
          state.data.data.push(action.payload.data);
        }
        state.errors = [];
      })
      .addCase(createPriceList.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      .addCase(updatePriceList.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updatePriceList.fulfilled, (state, action) => {
        state.isLoading = false;
        const indexAt = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (indexAt !== -1) {
          state.data.data[indexAt] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updatePriceList.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      .addCase(deletePriceList.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deletePriceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data = state.data.data.filter(
          (el) => el._id !== action.payload.data._id
        );
        state.errors = [];
      })
      .addCase(deletePriceList.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default priceListSlice.reducer;
