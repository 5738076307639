import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyD4C5TkQeWA8QYqq2VI7GepBdoUThdjrgQ",
  authDomain: "ahjzli-78ca9.firebaseapp.com",
  projectId: "ahjzli-78ca9",
  storageBucket: "ahjzli-78ca9.appspot.com",
  messagingSenderId: "125357809665",
  appId: "1:125357809665:web:c88d157790e38615a22000",
  measurementId: "G-1L9TGEX0YV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
export const messaging = getMessaging(app);
