import { Box, Flex, Button } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateNotification } from "../../../store/notifications/notificationsSlice";
import theme from "../../global/theme";
import { useState } from "react";
import OrderNotes from "../orders/OrderNotes";

const NotificationsList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOrderNotes, setShowOrderNotes] = useState(false);

  return (
    <Flex
      direction="column"
      bg="white"
      borderRadius="md"
      w="100%"
      boxShadow="md"
      overflowY="auto"
    >
      {data.map((el) => (
        <Box
          key={el._id}
          opacity={el.is_read ? 0.8 : 1}
          className="cursor-pointer"
          display="flex"
          alignItems="center"
          bg={el.is_read ? theme.bg : "white"}
          borderRadius="md"
          mb={2}
          p={4}
          boxShadow="sm"
          _hover={{
            bg: el.is_read ? theme.bg : "gray.50",
            transform: "scale(1.02)",
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={() => {
            const navigateTo = () => {
              if (el.type === "order_note") {
                // navigate(`/orders/${el.order?._id}/notes`, {
                //   state: el.order,
                // });
                setShowOrderNotes(el.order);
              } else if (el.type === "order_status") {
                navigate(`/orders/order_details/${el.order?._id}`);
              } else if (el.type === "request") {
                navigate("/requests");
              } else if (el.type === "ticket") {
                navigate("/tickets");
              }
            };

            if (!el.is_read) {
              dispatch(updateNotification({ _id: el._id, is_read: true }))
                .unwrap()
                .then(navigateTo);
            } else {
              navigateTo();
            }
          }}
        >
          <Box
            color={!el.is_read && theme.error}
            borderRadius="md"
            flex={1}
            textAlign="right"
          >
            <Box display="inline-block" me={3}>
              {el.content}
            </Box>
          </Box>
        </Box>
      ))}
      {showOrderNotes && (
        <OrderNotes
          data={showOrderNotes}
          onClose={() => {
            setShowOrderNotes(false);
          }}
        />
      )}
    </Flex>
  );
};

export default NotificationsList;
