import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Image,
  Td,
  Badge,
} from "@chakra-ui/react";

import Filter from "./actions/Filter";

import * as FiIcons from "react-icons/fi";
import { GiShoppingCart } from "react-icons/gi";
import { TbShoppingBagCheck } from "react-icons/tb";
import { GiTakeMyMoney } from "react-icons/gi";
import { BiSolidShoppingBags } from "react-icons/bi";
import { LiaShoppingCartSolid } from "react-icons/lia";
import { GiMoneyStack } from "react-icons/gi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { LiaPercentSolid } from "react-icons/lia";
import { getStatistics } from "../../../store/dashboard/statisticsSlice";
import { getImages } from "../../../store/images/imageSlice";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

// import "./styles.css";

// // import required modules
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import theme from "../../global/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { DashboardWrapper } from "./DashboardStyle";
import systemBg from "../../../assets/images/logo.png";
import banner from "../../../assets/images/Designer (4).jpeg";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../../store/products/productsSlice";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide(); // Automatically move to the next slide
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [currentIndex]); // Depend on currentIndex to clear the interval correctly

  return (
    <div className="relative w-full mx-auto">
      <div className="relative overflow-hidden h-96">
        {/* Images */}
        {images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              onClick={() => navigate(image?.relatedUrl)}
              className={`absolute cursor-pointer transition-transform duration-500 ease-in-out w-full h-full ${
                index === currentIndex ? "translate-x-0" : "translate-x-full"
              }`}
              style={{
                transform: `translateX(${(index - currentIndex) * 100}%)`,
              }}
            >
              <img
                src={`/settings/${image.image}`}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-fit" // Ensure image covers the area
                style={{ objectFit: "fit", width: "100%", height: "80%" }} // Cover the full screen
              />
            </div>
          ))}
      </div>

      {/* Left Arrow */}
      <button
        className="absolute left-0 p-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
        onClick={prevSlide}
      >
        &#8592;
      </button>

      {/* Right Arrow */}
      <button
        className="absolute right-0 p-2 text-white transform -translate-y-1/2 bg-gray-800 rounded-full top-1/2"
        onClick={nextSlide}
      >
        &#8594;
      </button>

      {/* Dots */}
      <div className="absolute flex gap-2 transform -translate-x-1/2 bottom-10 left-1/2">
        {images.length > 0 &&
          images.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? "bg-blue-500" : "bg-gray-400"
              }`}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const defaultFilter = { is_active: true, is_popular: true };
  const [filterProducts, setFilterProducts] = useState(
    JSON.stringify(defaultFilter)
  );
  useEffect(() => {
    dispatch(getProducts({ filter: filterProducts }));
  }, [dispatch, filterProducts]);
  const statistics = useSelector((state) => state.statistics);
  const auth = useSelector((state) => state.auth);
  const { data: images } = useSelector((state) => state.images);
  const [filter, setFilter] = useState({});
  useEffect(() => {
    dispatch(getImages());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getStatistics({ filter: JSON.stringify(filter) }));
  }, [dispatch, filter]);

  return (
    <DashboardWrapper>
      {/* Replace these SwiperSlide components with dynamic image loading */}

      <ImageSlider images={images} />

      <Flex paddingBlock={8} justifyContent="space-between" alignItems="center">
        <Text
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={{ base: 12, xl: 15 }}
          color={theme.dark}
        >
          التقارير العامة
        </Text>
        <Filter
          isLoading={statistics.isLoading}
          handleFilter={(values) => setFilter(values)}
          handleClearFilter={() => setFilter({})}
        />
      </Flex>

      {((auth.user.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard")) ||
        auth.user.role === "moderator" ||
        auth.user.role === "marketer" ||
        auth.user.role === "merchant") && (
        <SimpleGrid
          columns={{ base: 2, lg: 3, xl: 5 }}
          spacing={{ base: 4, xl: 6 }}
        >
          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F78B00">
                <GiShoppingCart size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfToday}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات اليوم
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <LiaShoppingCartSolid size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfWeek}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات هذا الاسبوع
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <BiSolidShoppingBags size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.ordersOfMonth}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                طلبات هذا الشهر
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="bg-[#0C2D57] text-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color="#F44336">
                <TbShoppingBagCheck size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {statistics.data?.totalOrders}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                اجمالي الطلبات
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.success}>
                  <GiTakeMyMoney size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  {(statistics.data?.totalDoneRequests &&
                    statistics.data?.totalDoneRequests[0]?.amount) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  ارباح تم سحبها
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color={theme.success}>
                <GiMoneyStack size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                {(statistics.data?.totalSales &&
                  statistics.data?.totalSales[0]?.total) ??
                  0}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                اجمالي المبيعات
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.success}>
                  <FaMoneyBillTrendUp size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  {(statistics.data?.totalIncome &&
                    statistics.data?.totalIncome[0]?.commission) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  اجمالي الارباح
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            padding={{ base: 3, xl: 6 }}
            alignItems="center"
            justifyContent="space-between"
            className="text-[#FFB0B0] bg-[#FC6736] rounded-xl shadow-xl hover:shadow-md transition-shadow"
          >
            <Flex justifyContent="space-between">
              <Box color={theme.light}>
                <FiIcons.FiPercent size={40} />
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                <span style={{ color: theme.light }}>
                  {statistics.data?.percent?.toFixed(2) ?? "-"}
                </span>
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 12, xl: 15 }}
                textTransform="capitalize"
              >
                نسبة التسليم
              </Text>
            </Box>
          </Flex>

          {(auth.user.role === "admin" || auth.user.role === "marketer") && (
            <Flex
              boxShadow={theme.shadow}
              padding={{ base: 3, xl: 6 }}
              alignItems="center"
              justifyContent="space-between"
              className="text-[#FFB0B0] bg-[#FC6736]  rounded-xl shadow-xl hover:shadow-md transition-shadow"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.light}>
                  <LiaPercentSolid size={40} />
                </Box>
              </Flex>
              <Box>
                <Text fontWeight="bold" fontSize={{ base: 18, xl: 27 }} mt={4}>
                  <span style={{ color: theme.light }}>
                    {statistics.data?.callCenterQuality?.toFixed(2) ?? "-"}
                  </span>
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 12, xl: 15 }}
                  textTransform="capitalize"
                >
                  جودة العملاء ونسبة التأكيد
                </Text>
              </Box>
            </Flex>
          )}
        </SimpleGrid>
      )}
      {auth.user?.role === "marketer" && (
        <>
          {" "}
          <Flex
            paddingBlock={8}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              fontSize={{ base: 15, xl: 18 }}
              color={theme.dark}
            >
              💰 الأعلي مبيعاً 💰
            </Text>
          </Flex>
          <Box className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {products?.data?.data?.map((product) => (
              <Box
                key={product._id}
                onClick={() => navigate(`/available_products/${product._id}`)}
                className="border rounded-lg p-4 cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <Image
                  src={`/products/${product.image}`}
                  alt={product.name}
                  className="h-48 w-full object-cover mb-2 rounded-md"
                />
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  className="text-center"
                >
                  {product.name}
                </Text>
              </Box>
            ))}
          </Box>
        </>
      )}
      {auth.user?.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard") && (
          <Box>
            <Flex
              paddingBlock={8}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                fontWeight="bold"
                textTransform="capitalize"
                fontSize={{ base: 12, xl: 15 }}
                color={theme.dark}
              >
                {t("general.details")}
              </Text>
            </Flex>

            <SimpleGrid
              columns={{ base: 2, lg: 3, xl: 5 }}
              spacing={{ base: 4, xl: 6 }}
            >
              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.totalProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.activeProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات النشطه
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.disActiveProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات الغير نشطة
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.users}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المستخدمين
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.merchants}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    التجار
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 18, xl: 27 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.marketers}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 12, xl: 15 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المسوقين
                  </Text>
                </Box>
              </Flex>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, lg: 2, xl: 3 }} spacing={6} mt={8}>
              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الآكثر نشاطا
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.mostMarketers?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الاكثر نشاطا هذا الاسبوع
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.marketersOfWeek?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <Box>
                <Text
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={{ base: 12, xl: 15 }}
                  color={theme.dark}
                  mb={4}
                >
                  المسوقين الاكثر نشاطا هذا الشهر
                </Text>
                <Table bg="white">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>اسم المسوق</Th>
                      <Th>عدد الاوردرات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {statistics.data?.marketersOfMonth?.map((el, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{el.user?.name}</Td>
                        <Td>{el.orderCount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </SimpleGrid>
          </Box>
        )}
    </DashboardWrapper>
  );
};

export default Dashboard;
