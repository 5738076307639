import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import ProtectedRoutes from "./ProtectedRoutes";

import Login from "../modules/auth/Login";
import Register from "../modules/auth/Register";
import ResetPassword from "../modules/auth/ResetPassword";
import ChangePassword from "../modules/auth/ChangePassword";

import Notifications from "../modules/notifications/Notifications";
import Profile from "../modules/profile/Profile";
import AccountTab from "../modules/profile/tabs/AccountTab";
import OrdersTab from "../modules/profile/tabs/OrdersTab";
import UpdateTab from "../modules/profile/tabs/UpdateTab";

import Dashboard from "../modules/dashboard/Dashboard";

import Rules from "../modules/rules/Rules";

import Categories from "../modules/categories/Categories";

import ShippingCompanies from "../modules/shippingCompanies/ShippingCompanies";

import PriceList from "../modules/priceList/PriceList";

import Users from "../modules/users/Users";

import Moderators from "../modules/moderators/Moderators";

import Warehouses from "../modules/warehouses/Warehouses";
import Announcements from "../modules/announcements/Announcements";

import Products from "../modules/products/Products";
import Available from "../modules/products/available/Available";
import ProductDetails from "../modules/products/available/ProductDetails";
import MyProducts from "../modules/myProducts/MyProducts";
import ProductsOrder from "../modules/products/ProductsOrder/ProductsOrder.js";

import Orders from "../modules/orders/Orders";
import OrderDetails from "../modules/orders/OrderDetails";
import OrderNotes from "../modules/orders/OrderNotes";

import ExcelOrders from "../modules/excelOrders/ExcelOrders";
import Statistics from "../modules/statistics/Statistics";
import MyStatistics from "../modules/mystatistics/MyStatistics";

import Cart from "../modules/cart/Cart";

import MerchantsAccounts from "../modules/merchantsAccounts/MerchantsAccounts";

import MarketersAccounts from "../modules/marketersAccounts/MarketersAccounts";

import Requests from "../modules/requests/Requests";

import Supports from "../modules/supports/Supports";
import BlackList from "../modules/blacklist/BlackList.jsx";

const Navigations = () => {
  const auth = useSelector((state) => state.auth);

  const permissions = auth.user?.rule?.permissions ?? [];

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset_password" element={<ResetPassword />} />
      <Route path="change_password" element={<ChangePassword />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="notifications" element={<Notifications />} />
        <Route path="profile" element={<Profile />}>
          <Route path="account" element={<AccountTab />} />
          <Route path="orders" element={<OrdersTab />} />
          <Route path="update" element={<UpdateTab />} />
        </Route>

        <Route path="" element={<Dashboard />} />

        <Route
          path="rules"
          element={
            permissions.includes("manage_settings") ? (
              <Rules />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="categories"
          element={
            permissions.includes("manage_settings") ? (
              <Categories />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="shipping_companies"
          element={
            permissions.includes("manage_settings") ? (
              <ShippingCompanies />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route path="price_list" element={<PriceList />} />

        <Route
          path="users"
          element={
            permissions.includes("manage_users") ? (
              <Users />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="moderators"
          element={
            auth.user?.role === "marketer" ? <Moderators /> : <h1>not allow</h1>
          }
        />

        <Route
          path="warehouses"
          element={
            permissions.includes("manage_warehouses") ? (
              <Warehouses />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="statistics"
          element={
            permissions.includes("manage_products") ? (
              <Statistics />
            ) : (
              <h1>not allow</h1>
            )
          }
        />
        <Route
          path="mystatistics"
          element={
            auth.user?.role === "marketer" ? (
              <MyStatistics />
            ) : (
              <h1>not allow</h1>
            )
          }
        />
        <Route
          path="products"
          element={
            permissions.includes("manage_products") ? (
              <Products />
            ) : (
              <h1>not allow</h1>
            )
          }
        />
        <Route
          path="order_products"
          element={
            permissions.includes("manage_products") ? (
              <ProductsOrder />
            ) : (
              <h1>not allow</h1>
            )
          }
        />
        <Route path="available_products" element={<Available />} />
        <Route
          path="favorite_products"
          element={<Available favorite={true} />}
        />
        <Route
          path="available_products/:productId"
          element={<ProductDetails />}
        />
        <Route
          path="blacklist"
          element={
            auth.user?.role === "admin" ? <BlackList /> : <h1>not allow</h1>
          }
        />
        <Route
          path="my_products"
          element={
            auth.user?.role === "merchant" ? <MyProducts /> : <h1>not allow</h1>
          }
        />
        <Route
          path="announcements"
          element={
            auth.user?.role === "admin" ? <Announcements /> : <h1>not allow</h1>
          }
        />
        <Route path="orders" element={<Orders />} />
        <Route
          path="orders/order_details/:orderId"
          element={
            auth.user?.role === "moderator" ||
            auth.user?.role === "admin" ||
            auth.user?.role === "marketer" ? (
              <OrderDetails />
            ) : (
              <h1>not allow</h1>
            )
          }
        />
        <Route
          path="orders/:orderId/notes"
          element={
            auth.user?.role === "moderator" ||
            auth.user?.role === "admin" ||
            auth.user?.role === "marketer" ? (
              <OrderNotes />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="excel_orders"
          element={
            auth.user?.allow_upload_excel === true ? (
              <ExcelOrders />
            ) : (
              <h1>يتم فتح الخاصية عند وصول ٣٠ طلب يومي</h1>
            )
          }
        />

        <Route
          path="cart"
          element={
            auth.user?.role === "moderator" ||
            auth.user?.role === "marketer" ||
            auth.user?.role === "admin" ? (
              <Cart />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="merchants_accounts"
          element={
            permissions.includes("manage_accounts") ? (
              <MerchantsAccounts />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="marketers_accounts"
          element={
            permissions.includes("manage_accounts") ? (
              <MarketersAccounts />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="requests"
          element={
            permissions.includes("manage_requests") ||
            auth.user?.role === "marketer" ||
            auth.user?.role === "merchant" ? (
              <Requests />
            ) : (
              <h1>not allow</h1>
            )
          }
        />

        <Route
          path="supports"
          element={
            auth.user?.role !== "moderator" ? <Supports /> : <h1>not allow</h1>
          }
        />
      </Route>
    </Routes>
  );
};

export default Navigations;
