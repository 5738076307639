import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { register as authRegister } from "../../../store/auth/authSlice";

import brandImage from "../../../assets/images/logo.png";
import authBg from "../../../assets/images/img-2.png";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const onSubmit = (values) => {
    dispatch(authRegister(values))
      .unwrap()
      .then(() => navigate("/login"))
      .catch((error) => alert(error.errors?.[0]?.msg));
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="grid w-full grid-cols-1 overflow-hidden bg-white rounded-lg shadow-xl lg:grid-cols-2">
        {/* Left Side - Image Section */}
        <div
          className="bg-center bg-no-repeat lg:block animate-slideInRight"
          style={{ backgroundImage: `url(${authBg})` }}
        >
          <div className="flex flex-col items-center h-full bg-gradient-to-r from-black/40 to-transparent">
            <img src={brandImage} alt="Logo" className="h-16 mb-6" />

            <h1 className="mb-4 text-4xl font-extrabold leading-tight text-white">
              مرحبًا بك مجددًا
            </h1>
            <p className="text-lg text-black/50 dark:text-gray-200">
              إذا لم يكن لديك حساب، قم بإنشاء حساب جديد
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center p-8 sm:p-12">
          <div className="w-full max-w-md mx-auto">
            <h2 className="mb-6 text-3xl font-semibold text-center text-gray-800">
              قم بإنشاء الحساب
            </h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-600">
                  الاسم
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="الاسم"
                  {...register("name", { required: t("validation.required") })}
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.name.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-600">
                  البريد الالكتروني
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="البريد الالكتروني"
                  {...register("email", { required: t("validation.required") })}
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-600">
                  الهاتف
                </label>
                <input
                  type="tel"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="الهاتف"
                  {...register("phone", {
                    required: t("validation.required"),
                    minLength: {
                      value: 11,
                      message: `${t("validation.min_length")} 11`,
                    },
                  })}
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.phone.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-600">
                  كلمة المرور
                </label>
                <div className="relative">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="كلمة المرور"
                    {...register("password", {
                      required: t("validation.required"),
                      minLength: {
                        value: 8,
                        message: `${t("validation.min_length")} 8`,
                      },
                    })}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 px-3 text-gray-600 left-5"
                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                  >
                    {isPasswordShown ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-600">
                  نوع الحساب
                </label>
                <select
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register("role", { required: t("validation.required") })}
                >
                  <option value="marketer">مسوق</option>
                  <option value="merchant">تاجر</option>
                </select>
                {errors.role && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.role.message}
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between">
                <Link
                  to="/reset_password"
                  className="text-sm text-blue-500 hover:underline"
                >
                  {t("pages.auth.forget_password")}
                </Link>
              </div>

              <button
                type="submit"
                className="w-full py-3 font-medium text-white transition duration-200 bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                disabled={auth.isLoading}
              >
                {t("pages.auth.register")}
              </button>

              <div className="mt-4 text-center">
                <Link to="/login" className="text-sm text-gray-600">
                  {t("pages.auth.have_an_account")}{" "}
                  <span className="text-blue-500 hover:underline">
                    {t("pages.auth.login")}
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>

        {/* Right Side - Registration Form */}
      </div>
    </div>
  );
};

export default Register;
