import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Select,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import UsersTable from "./UsersTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import OpenModal from "./actions/OpenModal";
import Filter from "./actions/Filter";

import { getUsers } from "../../../store/users/usersSlice";

import { UsersWrapper } from "./UsersStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";

const Users = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));
  const [role, setRole] = useState("");
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [showOpenUser, setShowOpenUser] = useState(false);
  useEffect(() => {
    if (role) {
      setFilter(JSON.stringify({ role }));
    } else {
      setFilter(JSON.stringify({}));
    }
    setPage(1);
  }, [role]);
  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <UsersWrapper>
      <Breadcrumbs
        currentPage={t("pages.users.users")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {users.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {users.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateUser(true)}
          >
            {t("general.create")}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={users.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={users.isLoading}
          />

          <Select
            placeholder="اختر النوع"
            mb={4}
            mr={10}
            onChange={(e) => setRole(e.target.value)}
            value={role}
            size="md"
            w="50%"
            variant="outline"
          >
            <option value="">الكل</option>
            <option value="marketer">مسوق</option>
            <option value="merchant">تاجر</option>
            <option value="moderator">مودريتر</option>

            <option value="admin">أدمن</option>
          </Select>
        </Flex>
      </SimpleGrid>

      {users.data?.itemsCount > 0 ? (
        <UsersTable
          data={users.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateUser(el)}
          handleOpen={(el) => setShowOpenUser(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.users.no_users")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={users.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateUser && (
        <CreateModal onClose={() => setShowCreateUser(false)} />
      )}
      {showUpdateUser && (
        <UpdateModal
          data={showUpdateUser}
          onClose={() => setShowUpdateUser(false)}
        />
      )}
      {showOpenUser && (
        <OpenModal data={showOpenUser} onClose={() => setShowOpenUser(false)} />
      )}
    </UsersWrapper>
  );
};

export default Users;
