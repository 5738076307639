import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";

import Table from "../../shared/table/Table";
import { IoBagRemove } from "react-icons/io5";
import { cities, governorates } from "../../../utilities/places";
import { Thead, Tbody, Tr, Th, Td, Text, useToast } from "@chakra-ui/react";
import { FaEye, FaNotesMedical } from "react-icons/fa";
import theme from "../../global/theme";
import { CopyToClipboard } from "react-copy-to-clipboard";

const OrdersTable = ({
  data,
  handleOrderStatus,
  handleSelectOrder,
  handleSelectAllOrders,
  setShowOrderNotes,
  setShowOrderProducts,
  showDetails = false,
  handleUpdate,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const toast = useToast({ status: "success", position: "top" });

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);
  const systemRevenue = (items) => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const totalPurchasePrice = items[i].total_purchase_price;
      const totalSalePrice = items[i].total_sale_price;
      total += totalSalePrice - totalPurchasePrice;
    }
    return total;
  };

  const marketer = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
      variant="striped"
      colorScheme="teal"
      size="sm"
    >
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.orders.serial_number")}</Th>
          <Th>مودريتور</Th>
          <Th>{t("pages.orders.client_name")}</Th>
          <Th>{t("pages.orders.client_phone1")}</Th>
          <Th>{t("pages.orders.products")}</Th>
          <Th>{t("pages.orders.total")}</Th>
          <Th>{t("pages.orders.commission")}</Th>
          <Th>تاريخ الانشاء</Th>
          <Th>{t("pages.orders.last_update")}</Th>
          <Th>{t("pages.orders.governorate")}</Th>
          <Th>{t("pages.orders.note")}</Th>

          <Th>{t("pages.orders.status")}</Th>
          {!showDetails && <Th>{t("general.action")}</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el._id}>
            <Td>{index + 1}</Td>
            <Td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>{el.moderator?.name || "-"}</Td>
            <Td>{el.client_name}</Td>
            <Td>
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </Td>
            {showDetails ? (
              <Td>
                {el.items?.map((item, idx) => (
                  <Text
                    key={idx}
                    fontSize="md"
                    mb={2}
                    p={2}
                    bg="gray.50"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <strong>({item.qty})</strong> قطعة {item.product?.name} -{" "}
                    <strong>
                      {
                        item.product.properties.find(
                          (it) => it._id === item.property
                        )?.key
                      }
                    </strong>
                    {data.items?.length !== idx + 1 && (
                      <span style={{ margin: "0 8px" }}> </span>
                    )}
                  </Text>
                ))}
              </Td>
            ) : (
              <Td
                className="text-center cursor-pointer "
                onClick={() => setShowOrderProducts(el)}
              >
                <IoBagRemove size={30} className="text-center text-[#FC6736]" />
              </Td>
            )}
            <Td>{el.total}</Td>
            <Td>{el.commission}</Td>
            <Td>{new Date(el.created_at).toLocaleDateString()}</Td>
            <Td>
              {new Date(el.updated_at).toLocaleDateString()}

              {el.holding_to
                ? t("pages.orders.holding_to") -
                  new Date(el.holding_to).toLocaleString()
                : ""}
            </Td>
            <Td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </Td>
            <Td>{el.note}</Td>

            <Td>
              <Box
                as="span"
                borderRadius="md"
                px={2}
                py={1}
                fontSize="xs"
                color="white"
                bg={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange.400"
                    : el.status === "shipped"
                    ? "blue.400"
                    : el.status === "available"
                    ? "green.400"
                    : "red.400"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
            </Td>
            {!showDetails && (
              <Td>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Button
                    leftIcon={<FaEye />}
                    colorScheme="blue"
                    size="xs"
                    onClick={() => navigate(`/orders/order_details/${el._id}`)}
                  >
                    تفاصيل
                  </Button>

                  <Button
                    leftIcon={<FaNotesMedical />}
                    colorScheme="teal"
                    size="xs"
                    onClick={() => {
                      setShowOrderNotes(el);
                    }}
                    position="relative"
                  >
                    ملاحظات
                    {el.replies.length > 0 && (
                      <Box
                        position="absolute"
                        top="-5px"
                        right="-5px"
                        width="15px"
                        height="15px"
                        bg={theme.error}
                        color={theme.light}
                        borderRadius="50%"
                        fontSize="8px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {el.replies.length}
                      </Box>
                    )}
                  </Button>

                  {el.status !== "pending" && (
                    <Button
                      size="xs"
                      colorScheme="yellow"
                      onClick={() => handleOrderStatus(el)}
                    >
                      تتبع
                    </Button>
                  )}
                </Box>
                {el.status === "pending" &&
                  el?.whats1_clicked == 0 &&
                  el?.whats2_clicked == 0 && (
                    <>
                      <Button
                        flex="1"
                        bg="transparent"
                        color="green"
                        size="xs"
                        mb={2}
                        onClick={() => handleUpdate(el)}
                      >
                        تعديل
                      </Button>
                      <Button
                        flex="1"
                        bg="transparent"
                        color="red"
                        size="xs"
                        mb={2}
                        onClick={() => handleCancel(el)}
                      >
                        إلغاء
                      </Button>
                    </>
                  )}
              </Td>
            )}
          </Tr>
        ))}
        <Tr>
          <Td></Td>
          <Td>الإجمالي</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td>{orders?.data?.total}</Td>
          <Td>{orders?.data?.totalCommissions}</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );

  const moderator = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
      variant="striped"
      colorScheme="teal"
      size="sm"
    >
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.orders.serial_number")}</Th>
          <Th>{t("pages.orders.client_name")}</Th>
          <Th>{t("pages.orders.client_phone1")}</Th>
          <Th>{t("pages.orders.governorate")}</Th>
          <Th>{t("pages.orders.products")}</Th>
          <Th>{t("pages.orders.total")}</Th>
          <Th>تاريخ الانشاء</Th>
          <Th>{t("pages.orders.last_update")}</Th>
          <Th>{t("pages.orders.note")}</Th>
          <Th>{t("pages.orders.holding_to")}</Th>
          <Th>{t("pages.orders.status")}</Th>
          {!showDetails && <Th>{t("general.action")}</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el._id}>
            <Td>{index + 1}</Td>
            <Td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>{el.client_name}</Td>
            <Td>
              {" "}
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </Td>
            {showDetails ? (
              <Td>
                {el.items?.map((item, idx) => (
                  <Text
                    key={idx}
                    fontSize="md"
                    mb={2}
                    p={2}
                    bg="gray.50"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <strong>({item.qty})</strong> قطعة {item.product?.name} -{" "}
                    <strong>
                      {
                        item.product.properties.find(
                          (it) => it._id === item.property
                        )?.key
                      }
                    </strong>
                    {data.items?.length !== idx + 1 && (
                      <span style={{ margin: "0 8px" }}> </span>
                    )}
                  </Text>
                ))}
              </Td>
            ) : (
              <Td
                className="text-center cursor-pointer "
                onClick={() => setShowOrderProducts(el)}
              >
                <IoBagRemove size={30} className="text-center text-[#FC6736]" />
              </Td>
            )}
            <Td>{el.total}</Td>
            <Td>{new Date(el.created_at).toLocaleDateString()}</Td>
            <Td>{new Date(el.updated_at).toLocaleDateString()}</Td>
            <Td>{el.note}</Td>
            <Td>
              {el.holding_to ? new Date(el.holding_to).toLocaleString() : "-"}
            </Td>
            <Td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
            </Td>
            {!showDetails && (
              <Td>
                <Box>
                  <Button
                    bg={theme.error}
                    fontSize="10px"
                    size="xs"
                    color={theme.light}
                    mb={4}
                    onClick={() => navigate(`/orders/order_details/${el._id}`)}
                  >
                    تفاصيل الطلب
                  </Button>

                  <Button
                    flex="1"
                    bg={theme.blue}
                    color={theme.light}
                    size="xs"
                    mb={2}
                    onClick={() => {
                      setShowOrderNotes(el);
                    }}
                  >
                    <Box position="relative">
                      <span>ملاحظات الطلب</span>
                      {el.replies.length > 0 && (
                        <Box
                          position="absolute"
                          top="-15px"
                          left="-1px"
                          w="15px"
                          h="15px"
                          bg={theme.error}
                          color={theme.light}
                          borderRadius="50%"
                          fontSize="6px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {el.replies?.length ?? 0}
                        </Box>
                      )}
                    </Box>
                  </Button>
                </Box>

                {el.status !== "pending" && (
                  <Button
                    type="button"
                    size="xs"
                    onClick={() => handleOrderStatus(el)}
                  >
                    تتبع الطلب
                  </Button>
                )}
              </Td>
            )}
          </Tr>
        ))}
        <Tr>
          <Td></Td>
          <Td>الإجمالي</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td>{orders?.data?.total}</Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );

  const admin = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
      variant="striped"
      colorScheme="teal"
      size="sm"
    >
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>الربح</Th>
          <Th>مودريتور</Th>
          <Th>{t("pages.orders.serial_number")}</Th>
          <Th>{t("pages.orders.client_name")}</Th>
          <Th>{t("pages.orders.client_phone1")}</Th>
          <Th>{t("pages.orders.client_phone2")}</Th>
          <Th>{t("pages.orders.governorate")}</Th>
          <Th>{t("pages.orders.city")}</Th>
          <Th>{t("pages.orders.client_address")}</Th>
          <Th>{t("pages.orders.products")}</Th>
          <Th>{t("pages.orders.total")}</Th>
          <Th>{t("pages.orders.commission")}</Th>
          <Th>تاريخ الانشاء</Th>
          <Th>{t("pages.orders.last_update")}</Th>
          <Th>{t("pages.orders.note")}</Th>
          <Th>{t("pages.orders.holding_to")}</Th>
          <Th>شركة الشحن</Th>
          <Th>{t("pages.orders.status")}</Th>
          {!showDetails && <Th>{t("general.action")}</Th>}
          {!showDetails && (
            <Th>
              <Button type="button" onClick={handleSelectAllOrders}>
                تحديد الكل
              </Button>
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el._id}>
            <Td>{index + 1}</Td>
            <Td>{systemRevenue(el.items)}</Td>
            <Td>{el.moderator?.name || "-"}</Td>
            <Td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>{el.client_name}</Td>
            <Td>
              {" "}
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>{el.client_phone2 || "-"}</Td>
            <Td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </Td>
            <Td>{cities.find((city) => +city.id === el.city)?.city_name_ar}</Td>
            <Td>{el.client_address}</Td>
            {showDetails ? (
              <Td>
                {el.items?.map((item, idx) => (
                  <span
                    key={idx}
                    fontSize="md"
                    mb={2}
                    p={2}
                    bg="gray.50"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <strong>({item.qty})</strong> قطعة {item.product?.name} -{" "}
                    <strong>
                      {
                        item.product.properties.find(
                          (it) => it._id === item.property
                        )?.key
                      }
                    </strong>
                    {data.items?.length !== idx + 1 && (
                      <span style={{ margin: "0 8px" }}> </span>
                    )}
                  </span>
                ))}
              </Td>
            ) : (
              <Td
                className="text-center cursor-pointer "
                onClick={() => setShowOrderProducts(el)}
              >
                <IoBagRemove size={30} className="text-center text-[#FC6736]" />
              </Td>
            )}
            <Td>{el.total}</Td>
            <Td>{el.commission}</Td>
            <Td>{new Date(el.created_at).toLocaleDateString()}</Td>
            <Td>{new Date(el.updated_at).toLocaleDateString()}</Td>
            <Td>{el.note}</Td>
            <Td>
              {el.holding_to ? new Date(el.holding_to).toLocaleString() : "-"}
            </Td>
            <Td>{el.shipping_company?.name ?? "-"}</Td>
            <Td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
            </Td>
            {!showDetails && (
              <Td>
                <Box>
                  <Button
                    bg={theme.error}
                    fontSize="10px"
                    size="xs"
                    color={theme.light}
                    mb={4}
                    onClick={() => navigate(`/orders/order_details/${el._id}`)}
                  >
                    تفاصيل الطلب
                  </Button>

                  <Button
                    flex="1"
                    bg={theme.blue}
                    color={theme.light}
                    size="xs"
                    mb={2}
                    onClick={() => {
                      setShowOrderNotes(el);
                    }}
                  >
                    <Box position="relative">
                      <span>ملاحظات الطلب</span>
                      {el.replies.length > 0 && (
                        <Box
                          position="absolute"
                          top="-15px"
                          left="-1px"
                          w="15px"
                          h="15px"
                          bg={theme.error}
                          color={theme.light}
                          borderRadius="50%"
                          fontSize="6px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {el.replies?.length ?? 0}
                        </Box>
                      )}
                    </Box>
                  </Button>

                  {auth.user.role === "admin" && el.status === "pending" && (
                    <Button
                      flex="1"
                      bg="transparent"
                      color="green"
                      size="xs"
                      mb={2}
                      onClick={() => handleUpdate(el)}
                    >
                      <FiIcons.FiEdit size={20} />
                    </Button>
                  )}
                </Box>

                {el.status !== "pending" && (
                  <Button
                    type="button"
                    size="xs"
                    onClick={() => handleOrderStatus(el)}
                  >
                    تتبع الطلب
                  </Button>
                )}
              </Td>
            )}
            {!showDetails && (
              <Td>
                <input
                  type="checkbox"
                  style={{ width: "25px", height: "25px" }}
                  className="order-check"
                  onChange={(e) => handleSelectOrder(el, e)}
                />
              </Td>
            )}
          </Tr>
        ))}
        <Tr>
          <td></td>
          <td>الإجمالي</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{orders?.data?.total}</td>
          <td>{orders?.data?.totalCommissions}</td>
          <td></td>
          <td></td>
        </Tr>
      </Tbody>
    </Table>
  );

  const merchant = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
      variant="striped"
      colorScheme="teal"
      size="sm"
    >
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.orders.serial_number")}</Th>
          <Th>{t("pages.orders.products")}</Th>
          <Th>تاريخ الانشاء</Th>
          <Th>{t("pages.orders.last_update")}</Th>
          <Th>{t("pages.orders.governorate")}</Th>
          <Th>{t("pages.orders.holding_to")}</Th>
          <Th>{t("pages.orders.status")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el._id}>
            <Td>{index + 1}</Td>
            <Td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </Td>
            <Td>
              {el.items?.length &&
                el.items.map(
                  (item, idx) =>
                    item.merchant === auth.user._id &&
                    `(${item.qty}) قطعة ${item.product?.name} - ${
                      item.product.properties.find(
                        (it) => it._id === item.property
                      )?.key
                    } ${el.items?.length === idx + 1 ? "" : ""} `
                )}
            </Td>
            <Td>{new Date(el.created_at).toLocaleDateString()}</Td>
            <Td>{new Date(el.updated_at).toLocaleDateString()}</Td>
            <Td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </Td>
            <Td>
              {el.holding_to ? new Date(el.holding_to).toLocaleString() : "-"}
            </Td>
            <Td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td></Td>
          <Td>الإجمالي</Td>
          <Td></Td>
          <Td>{orders?.data?.totalPurchases}</Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );

  const authQ = {
    marketer,
    moderator,
    admin,
    merchant,
  };

  return (
    <div style={{ transform: "rotateX(180deg)" }}>{authQ[auth.user.role]}</div>
  );
};

export default OrdersTable;
