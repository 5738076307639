import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Flex,
  Button,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react";
import {
  FiMinimize2,
  FiClock,
  FiPackage,
  FiTruck,
  FiCheckCircle,
  FiXCircle,
  FiArrowRightCircle,
  FiArrowLeftCircle,
} from "react-icons/fi";

import theme from "../../../global/theme";

const statusIcons = {
  pending: FiClock,
  preparing: FiPackage,
  shipped: FiTruck,
  available: FiCheckCircle,
  skip: FiArrowRightCircle,
  holding: FiClock,
  ask_to_return: FiArrowRightCircle,
  returned1: FiXCircle,
  returned2: FiXCircle,
  declined1: FiXCircle,
  declined2: FiXCircle,
};

const OrderStatusModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={8} bg={theme.dark}>
        <Flex direction="column" spacing={4} color="white">
          <Box mb={4}>
            <Text fontSize="lg" fontWeight="bold">
              الحالة
            </Text>
            <Text>{new Date(data.created_at).toLocaleString()}</Text>
          </Box>

          <Flex direction="row" wrap="wrap" align="center" overflowX="auto">
            {data.actions.map((el, index) => {
              const Icon = statusIcons[el.status] || FiClock; // Default icon if status doesn't match
              return (
                <React.Fragment key={el._id}>
                  <Flex
                    direction="column"
                    align="center"
                    m={2}
                    p={4}
                    borderRadius="md"
                    bg="gray.800"
                    boxShadow="md"
                    width="auto"
                    position="relative"
                  >
                    <Icon className="text-3xl text-blue-500" />
                    <Text fontSize="md" fontWeight="semibold" mt={2}>
                      {t(`pages.orders.${el.status}`)}
                      {auth.user.role === "admin" && ` (${el.admin?.name})`}
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      {new Date(el.created_at).toLocaleString()}
                    </Text>
                    {/* Arrow to the next status with action number */}
                    <Box
                      position="absolute"
                      right="-30px"
                      top="50%"
                      zIndex={1}
                      display="flex"
                      alignItems="center"
                    >
                      <Text
                        fontSize="sm"
                        color="blue.500"
                        position="absolute"
                        top="-20px"
                        right="50%"
                        transform="translateX(50%)"
                      >
                        {index + 1}
                      </Text>
                      <FiArrowRightCircle className="text-2xl text-blue-500" />
                    </Box>
                  </Flex>
                </React.Fragment>
              );
            })}
          </Flex>

          <ModalFooter p={0}>
            <Flex justifyContent="flex-end" mt={8}>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                _hover={{ background: "red.700" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default OrderStatusModal;
