import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: {
    activities: [],
    activitiesList: [],
    marketers: [],
    products: [],
    rates: [],
    adminStatistics: [],
    dailyOrders: [],
    orders: {
      availableOrders: [],
      totalOrders: [],
    },
  },
  errors: [],
};

// Thunks
export const getStatisticsMarketers = createAsyncThunk(
  "reports/marketers",
  async (args, thunkApi) => {
    const { filter = {} } = args;
    try {
      const { data } = await axios.get(
        `/api/statistics_marketers?filter=${JSON.stringify(filter)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const getProductRates = createAsyncThunk(
  "reports/rates",
  async (args, thunkApi) => {
    try {
      const { filter = {}, page = 1, size = 20 } = args;

      const { data } = await axios.get(
        `/api/rates_products?filter=${JSON.stringify(
          filter
        )}&page=${page}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const getOrderStatistics = createAsyncThunk(
  "reports/getOrderStatistics",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/statistics-orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const dialyOrdersStatistics = createAsyncThunk(
  "reports/dailyOrders",
  async (args, thunkApi) => {
    try {
      const { filter = {}, page = 1, size = 20 } = args;

      const { data } = await axios.get(
        `/api/daily-orders?filter=${JSON.stringify(
          filter
        )}&page=${page}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      console.log(data);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearActivities(state) {
      state.data.activities = [];
      state.data.activitiesList = [];
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    // Handle getStatisticsMarketers
    builder
      .addCase(getStatisticsMarketers.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getStatisticsMarketers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.marketers = action.payload;
        state.errors = [];
      })
      .addCase(getStatisticsMarketers.rejected, (state, action) => {
        state.isLoading = false;
        state.data.marketers = [];
        state.errors = action.payload.errors;
      })

      // Handle getProductRates
      .addCase(getProductRates.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getProductRates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.rates = action.payload;
        state.errors = [];
      })
      .addCase(getProductRates.rejected, (state, action) => {
        state.isLoading = false;
        state.data.rates = [];
        state.errors = action.payload.errors;
      })

      // Handle getOrderStatistics
      .addCase(getOrderStatistics.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getOrderStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.orders = action.payload;
        state.errors = [];
      })
      .addCase(getOrderStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.data.orders = [];
        state.errors = action.payload.errors;
      })
      // Handle dailyOrdersStatistics
      .addCase(dialyOrdersStatistics.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(dialyOrdersStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.dailyOrders = action.payload;
        state.errors = [];
      })
      .addCase(dialyOrdersStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.data.dailyOrders = [];
        state.errors = action.payload.errors;
      });
  },
});

export const { clearActivities } = reportsSlice.actions;

export default reportsSlice.reducer;
