import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Image,
  useToast,
  InputGroup,
  InputRightElement,
  Divider,
  Alert,
  Link as ChakraLink,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { login } from "../../../store/auth/authSlice";

import authImage from "../../../assets/images/img-1.png";
import brandImage from "../../../assets/images/logo.png";

const Login = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 2000, status: "error" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <div className="flex flex-col-reverse min-h-screen lg:flex-row">
      {/* Right Side - Login Form */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center items-center p-4 lg:p-8 bg-[#EFECEC] animate-fadeIn">
        <div className="w-full max-w-md p-6 transition-transform duration-500 transform bg-white rounded-lg shadow-lg lg:p-8 hover:scale-105">
          <form
            onSubmit={handleSubmit((values) => {
              dispatch(login(values))
                .unwrap()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  toast({
                    description: error.errors && t(error.errors[0]?.msg),
                  });
                });
            })}
          >
            <Stack spacing={4}>
              <Heading className="text-xl lg:text-2xl text-center mb-4 lg:mb-6 text-[#0C2D57]">
                {t("pages.auth.login")}
              </Heading>
              <Divider marginBlock={6} />
              {auth.errors[0]?.msg === "auth.user_is_not_active" && (
                <>
                  <Alert
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    bg="red.50"
                    borderLeft="4px solid"
                    borderColor="red.400"
                    p={4}
                    mb={4}
                  >
                    <Text
                      fontWeight="bold"
                      color="red.600"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <FiIcons.FiAlertTriangle
                        style={{ marginRight: "8px", color: "red.600" }}
                      />
                      برجاء التواصل مع الدعم للتنشيط{" "}
                    </Text>

                    <ChakraLink
                      as={Link}
                      isExternal
                      to="https://www.facebook.com/groups/971236150398240"
                      ml={2}
                      display="flex"
                      alignItems="center"
                      color="yellow.800"
                      fontWeight="bold"
                      _hover={{ textDecoration: "underline" }}
                    >
                      <FiIcons.FiExternalLink
                        style={{ marginRight: "4px", color: "yellow.800" }}
                      />
                      الدعم
                    </ChakraLink>
                  </Alert>
                </>
              )}
              <FormControl>
                <FormLabel className="text-[#0C2D57]">
                  {t("pages.auth.email")}
                </FormLabel>
                <Input
                  type="text"
                  placeholder={t("pages.auth.email")}
                  className="p-2 border-gray-300 rounded-lg lg:p-3"
                  {...register("email", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.email?.message && (
                  <Text className="text-[#FC6736] text-sm">
                    {errors.email?.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel className="text-[#0C2D57]">
                  {t("pages.auth.password")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    placeholder={t("pages.auth.password")}
                    className="p-2 border-gray-300 rounded-lg lg:p-3"
                    {...register("password", {
                      required: `${t("validation.required")}`,
                      minLength: {
                        value: 8,
                        message: `${t("validation.min_length")} 8`,
                      },
                    })}
                  />
                  <InputRightElement>
                    <Button
                      p={0}
                      bg="none"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? (
                        <FiIcons.FiEyeOff />
                      ) : (
                        <FiIcons.FiEye />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text className="text-[#FC6736] text-sm">
                    {errors.password?.message}
                  </Text>
                )}
              </FormControl>

              <Link
                to="/reset_password"
                className="text-[#FC6736] text-sm lg:text-base"
              >
                {t("pages.auth.forget_password")}
              </Link>

              <Button
                type="submit"
                className="w-full bg-[#FC6736] text-white py-2 lg:py-3 rounded-lg hover:bg-[#FFB0B0] transition hover:scale-105 transform"
                isLoading={auth.isLoading}
              >
                {t("pages.auth.login")}
              </Button>

              <Link
                to="/register"
                className="mt-4 text-sm text-center text-gray-500"
              >
                {t("pages.auth.donot_have_account")}{" "}
                <span className="text-[#FC6736]">
                  {t("pages.auth.register")}
                </span>
              </Link>
            </Stack>
          </form>
        </div>
      </div>

      {/* Left Side - Branding */}
      <div className="lg:flex w-full lg:w-1/2 justify-center items-center bg-[#0C2D57] p-8 animate-slideInLeft">
        <div className="flex flex-col items-center justify-center h-full">
          <Image
            src={brandImage}
            className="h-[80px] lg:h-[100px] mb-8 animate-fadeInSlow"
          />
          <Heading className="mb-2 text-2xl text-white lg:text-3xl">
            {t("pages.auth.welcome_1")}
          </Heading>
          <Text className="text-[#FFB0B0] text-lg text-center">
            {t("pages.auth.welcome_2")}
          </Text>
          <Image src={authImage} className="max-w-xs mt-8 md:max-w-md" />
        </div>
      </div>
    </div>
  );
};

export default Login;
