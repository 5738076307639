import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import { getOrders, createNote } from "../../../store/orders/ordersSlice";
import theme from "../../global/theme";

const OrderNotes = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();

  const chatEndRef = useRef(null); // Ref for auto-scrolling

  // Scroll to the latest message
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (data) {
      dispatch(
        getOrders({
          page: 1,
          query: "",
          filter: JSON.stringify({ _id: data._id }),
        })
      );
    }
  }, [dispatch, data]);

  useEffect(() => {
    scrollToBottom();
  }, [orders.data?.data]);

  const currentOrder = orders.data?.data && orders.data.data[0];

  const handleFormSubmit = (values) => {
    dispatch(
      createNote({
        id: currentOrder?._id,
        sender: auth?.user?._id,
        ...values,
      })
    )
      .unwrap()
      .then(() => {
        reset({ body: "" });
        dispatch(
          getOrders({
            page: 1,
            query: "",
            filter: JSON.stringify({ _id: currentOrder._id }),
          })
        );
      });
  };

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        {/* Modern Header */}
        <ModalHeader>
          {/* <Flex justifyContent="space-between" alignItems="center">
            <Box fontSize="lg" fontWeight="bold">
              ملاحظات الطلب
            </Box>
            
          </Flex> */}
          <Flex justifyContent="space-between" mb={6}>
            <Box color={theme.blue}>
              <button
                onClick={() =>
                  navigate(`/orders/order_details/${currentOrder?._id}`)
                }
              >
                {t("pages.orders.serial_number")}: {currentOrder?.serial_number}
              </button>
            </Box>
            <Box>
              {t("pages.orders.client_name")}: {currentOrder?.client_name}
            </Box>
          </Flex>
        </ModalHeader>

        {/* Chat Body with auto-scroll */}
        <ModalBody
          className="scrollable-modal-body"
          maxH="400px"
          overflowY="auto"
        >
          {auth?.user._id === auth.user?._id ||
          currentOrder?.marketer?._id === auth?.user?._id ||
          currentOrder?.moderator?._id === auth?.user?._id ? (
            <>
              <Box>
                {currentOrder?.replies && currentOrder?.replies.length === 0 ? (
                  <Box
                    h="200px"
                    textAlign="center"
                    bg={theme.light}
                    boxShadow={theme.shadow}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    لا ملاحظات بعد
                  </Box>
                ) : (
                  <>
                    {currentOrder?.replies.map((el, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent={
                          el?.sender?._id === auth.user?._id
                            ? "flex-start"
                            : "flex-end"
                        }
                        mb={4}
                      >
                        <Box
                          className={`max-w-md p-4 rounded-lg ${
                            el?.sender?._id === auth.user?._id
                              ? "bg-blue-600 text-white"
                              : "bg-gray-200 text-gray-800"
                          } shadow-md`}
                        >
                          <Box className="mb-1 text-lg font-semibold">
                            {el?.sender?.name}{" "}
                            {el?.sender?._id === auth?.user?._id && "- me"}
                          </Box>
                          <Box className="mb-3 text-sm">{el.body}</Box>
                          <Box className="text-xs text-white-500">
                            {el.created_at
                              ? new Date(el.created_at).toLocaleString()
                              : "-"}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    {/* Reference to auto-scroll */}
                    <div ref={chatEndRef} />
                  </>
                )}
              </Box>
            </>
          ) : (
            <Box>{t("pages.orders.not_allowed")}</Box>
          )}
        </ModalBody>

        {/* Modern Footer */}
        <ModalFooter>
          {currentOrder && currentOrder?.status !== "closed" && (
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              style={{ width: "100%" }}
            >
              <Flex
                bg={theme.light}
                p={2}
                boxShadow={theme.shadow}
                borderRadius="md"
              >
                <Input
                  {...register("body", {
                    required: true,
                  })}
                  placeholder={"اكتب رسالتك"}
                  variant="filled"
                />
                <Button
                  disabled={orders.isLoading}
                  type="submit"
                  marginInlineStart={4}
                  colorScheme="blue"
                >
                  إرسال <FiIcons.FiSend />
                </Button>
              </Flex>
            </form>
          )}
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {t("general.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderNotes;
