import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  Image,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateCategory } from "../../../../store/categories/categoriesSlice";

import theme from "../../../global/theme";

const UpdateModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  watch(["image"]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            const formData = new FormData(event.target);
            dispatch(updateCategory({ id: values._id, values: formData }))
              .unwrap()
              .then((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("general.update")}
          </ModalHeader>
          <ModalBody>
            {categories.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {categories.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.categories.image")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type="file"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    height="auto"
                    padding=".4rem 1rem"
                    _placeholder={{ textTransform: "capitalize" }}
                    {...register("image")}
                  />
                  <InputRightElement
                    color={theme.light}
                    children={<FiIcons.FiImage />}
                  />
                </InputGroup>

                {typeof getValues("image") === "string" &&
                  getValues("image") !== "" && (
                    <>
                      <Button
                        type="button"
                        mt={4}
                        bg="gray.300"
                        textTransform="capitalize"
                        onClick={() => setValue("image", "")}
                      >
                        {t("general.remove")}
                      </Button>
                      <Image
                        src={`/categories/${getValues("image")}`}
                        maxH={150}
                        mt={4}
                        alt="user avatar"
                      />
                      <Input
                        type="hidden"
                        name="edited_image"
                        defaultValue={getValues("image")}
                      />
                    </>
                  )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.categories.name")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.categories.name")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={categories.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={categories.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateModal;
