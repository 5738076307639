import React from "react";
import { Box, Flex, Text, Heading, VStack } from "@chakra-ui/react";
import { cities } from "../../../utilities/places";

const OrderStatistics = ({ data }) => {
  return (
    <Flex direction={{ base: "column", md: "row" }} className="p-4">
      {/* Available Orders Section */}
      <Box
        className="w-full p-4 md:w-1/2"
        bg="gray.100"
        borderRadius="md"
        boxShadow="md"
      >
        <Heading size="md" mb={4} className="text-center text-blue-600">
          المدن الأكثر تسليماً
        </Heading>
        <VStack spacing={4} align="stretch">
          {data?.availableOrders?.map((city, index) => (
            <Flex
              key={index}
              justify="space-between"
              className="p-2 bg-white rounded-lg shadow"
            >
              <Text className="font-semibold">
                {" "}
                {cities.find((c) => c.id == city.cityId)?.city_name_ar}
              </Text>
              <Text>{city?.availableOrdersCount}</Text>
            </Flex>
          ))}
        </VStack>
      </Box>

      {/* Total Orders Section */}
      <Box
        className="w-full p-4 md:w-1/2"
        bg="gray.100"
        borderRadius="md"
        boxShadow="md"
        mt={{ base: 4, md: 0 }}
      >
        <Heading size="md" mb={4} className="text-center text-green-600">
          المدن الأكثر طلبات
        </Heading>
        <VStack spacing={4} align="stretch">
          {data?.totalOrders?.map((city, index) => (
            <Flex
              key={index}
              justify="space-between"
              className="p-2 bg-white rounded-lg shadow"
            >
              <Text className="font-semibold">
                {cities.find((c) => c.id == city.cityId)?.city_name_ar}
              </Text>
              <Text>{city?.totalOrdersCount}</Text>
            </Flex>
          ))}
        </VStack>
      </Box>
    </Flex>
  );
};

export default OrderStatistics;
