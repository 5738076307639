import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import * as FaIcons from "react-icons/fa";

import { updateOrderCallAction } from "../../../store/orders/ordersSlice";
import {
  MdOutlinePendingActions,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import UpdateModal from "./actions/UpdateModal";
import UpdateOrdersStatusModal from "./actions/UpdateOrdersStatusModal";
import {
  FiArrowDownCircle,
  FiArrowRightCircle,
  FiArrowUpCircle,
  FiShoppingCart,
} from "react-icons/fi";
import { IoPersonCircleOutline } from "react-icons/io5";
import {
  Flex,
  Text,
  Box,
  SimpleGrid,
  Button,
  Divider,
  Alert,
  AlertIcon,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  FiMinimize2,
  FiClock,
  FiPackage,
  FiTruck,
  FiCheckCircle,
  FiXCircle,
  FiArrowLeftCircle,
} from "react-icons/fi";

import { cities, governorates } from "../../../utilities/places";
import CancelOrdersStatusModal from "./actions/CancelOrdersStatusModal";

import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import { Table, Tbody, Th, Thead, Tr, Td } from "@chakra-ui/react";

import { getOrders } from "../../../store/orders/ordersSlice";

import theme from "../../global/theme";
import OrderNotes from "./OrderNotes";
const statusIcons = {
  pending: FiClock,
  preparing: FiPackage,
  shipped: FiTruck,
  available: FiCheckCircle,
  skip: FiArrowRightCircle,
  holding: FiClock,
  ask_to_return: FiArrowRightCircle,
  returned1: FiXCircle,
  returned2: FiXCircle,
  declined1: FiXCircle,
  declined2: FiXCircle,
};
const OrderDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [showUpdateOrder, setShowUpdateOrder] = useState(false);
  const [showUpdateOrdersStatus, setShowUpdateOrdersStatus] = useState(false);
  const [showReverseOrdersStatus, setShowReverseOrdersStatus] = useState(false);
  const [showCancelOrdersStatus, setShowCancelOrdersStatus] = useState(false);
  const [showOrderNotes, setShowOrderNotes] = useState(false);
  const { orderId } = useParams();

  const currentOrder = orders.data?.data && orders.data.data[0];

  const generateWhatsappMessage = (serialNumber, clientPhone) => {
    const phone = "2" + clientPhone;
    const step1 = `اهلا بحضرتك عميلنا العزيز بنكلمك بخصوص تاكيد اوردر كود ${serialNumber}`;
    const step2 = `${currentOrder.items.map(
      (item, idx) =>
        `(${item.qty}) قطعة ${item.product?.name} - ${
          item.product.properties.find((it) => it._id === item.property)?.key
        } ${currentOrder.items?.length === idx + 1 ? "" : "//"} `
    )}`;
    const step3 = `الاجمالي المطلوب دفعه ${currentOrder.total} جنية.`;
    const step4 = `لتاكيد الاوردر وخروجة للشحن ارسل رقم (1)`;
    const step5 = `للتعديل على الطلب ارسل الرقم (2)`;
    const message = `${step1}\n\n${step2}\n${step3}\n\n${step4}\n${step5}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      phone
    )}&text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };
  const handleClick = (actionName, phoneNumber, serialNumber) => {
    dispatch(
      updateOrderCallAction({ _id: currentOrder._id, action_name: actionName })
    )
      .unwrap()
      .then(() => {
        switch (actionName) {
          case "whats1_clicked":
          case "whats2_clicked":
            generateWhatsappMessage(serialNumber, phoneNumber);
            break;
          case "phone1_clicked":
          case "phone2_clicked":
            window.location.href = `tel:2${phoneNumber}`;
            break;
          case "sms1_clicked":
          case "sms2_clicked":
            generateSmsMessage(serialNumber, phoneNumber);
            break;
          default:
            break;
        }
      });
  };
  const generateSmsMessage = (serialNumber, clientPhone) => {
    const phone = "2" + clientPhone;
    const step1 = `اهلا بحضرتك عميلنا العزيز بنكلمك بخصوص تاكيد اوردر كود ${serialNumber}`;
    const step2 = `${currentOrder.items.map(
      (item, idx) =>
        `(${item.qty}) قطعة ${item.product?.name} - ${
          item.product.properties.find((it) => it._id === item.property)?.key
        } ${currentOrder.items?.length === idx + 1 ? "" : "//"} `
    )}`;
    const step3 = `الاجمالي المطلوب دفعه ${currentOrder.total} جنية.`;
    const step4 = `لتاكيد الاوردر وخروجة للشحن ارسل رقم (1)`;
    const step5 = `للتعديل على الطلب ارسل الرقم (2)`;
    const message = `${step1}\n\n${step2}\n${step3}\n\n${step4}\n${step5}`;
    const smsUrl = `sms:${encodeURIComponent(phone)}?body=${encodeURIComponent(
      message
    )}`;
    window.location.href = smsUrl;
  };

  useEffect(() => {
    dispatch(
      getOrders({
        page: 1,
        query: "",
        filter: JSON.stringify({ _id: orderId }),
      })
    );
  }, [dispatch, orderId]);

  if (!currentOrder) {
    return <h1>waitng ...</h1>;
  }

  return (
    <>
      <Breadcrumbs
        currentPage={t("pages.orders.orders")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {orders.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {orders.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}
      {auth.user.role === "admin" && (
        <SimpleGrid columns={{ base: 2, md: 6 }} spacing={6} mb={8}>
          {(currentOrder.status === "pending" ||
            currentOrder.status === "holding") && (
            <Button
              type="button"
              bg={theme.success}
              color={theme.light}
              onClick={() => setShowUpdateOrder(currentOrder)}
            >
              تعديل الطلب
            </Button>
          )}
          {auth.user?.rule?.permissions?.includes("manage_orders") &&
            auth.user?.rule?.name === "superadmin" && (
              <Button
                variant="outline"
                type="button"
                ms={4}
                color={theme.error}
                borderColor={theme.error}
                onClick={setShowReverseOrdersStatus}
              >
                إرجاع حالة الطلب
              </Button>
            )}
          <Button
            variant="outline"
            type="button"
            ms={4}
            color={theme.success}
            borderColor={theme.success}
            onClick={setShowUpdateOrdersStatus}
          >
            تغيير حالة الطلب
          </Button>
          <Button
            bg={theme.blue}
            color={theme.light}
            ms={4}
            onClick={() => setShowOrderNotes(currentOrder)}
          >
            <Box position="relative">
              <span>ملاحظات الطلب</span>
              {currentOrder.replies.length > 0 && (
                <Box
                  position="absolute"
                  top="-15px"
                  left="-1px"
                  w="15px"
                  h="15px"
                  bg={theme.error}
                  color={theme.light}
                  borderRadius="50%"
                  fontSize="6px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {currentOrder.replies?.length ?? 0}
                </Box>
              )}
            </Box>
          </Button>
        </SimpleGrid>
      )}
      {auth.user.role === "marketer" && (
        <Flex mb={8}>
          {currentOrder.status === "pending" &&
            currentOrder?.whats1_clicked == 0 &&
            currentOrder?.whats2_clicked == 0 && (
              <>
                <Button
                  type="button"
                  bg={"red"}
                  color={theme.light}
                  onClick={() => setShowCancelOrdersStatus(currentOrder)}
                >
                  إلغاء الاورد
                </Button>
                <Button
                  ms={4}
                  type="button"
                  bg={theme.success}
                  color={theme.light}
                  onClick={() => setShowUpdateOrder(currentOrder)}
                >
                  تعديل الطلب
                </Button>
              </>
            )}
        </Flex>
      )}
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6} mb={8}>
        <div className="col-span-1 md:col-span-2">
          <div className="rounded-2xl show-md border-[1px] p-4 bg-white border-[#DCDFE4]">
            <div className="flex gap-4 text-2xl items-center p-4">
              <div className="rounded-full shadow-md bg-white p-3 flex items-center justify-center">
                <IoPersonCircleOutline />
              </div>
              تفاصيل العميل
            </div>
            <div className="rounded-xl border-[1px] bg-white border-[#DCDFE4]">
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  اسم العميل :
                  <span className="text-[#635BFF]">
                    {currentOrder.client_name}
                  </span>
                </div>
                <div className="text-[#667085]">
                  رقم الهاتف :
                  <span className="text-[#635BFF]">
                    {currentOrder.client_phone1}
                    {auth.user.role === "admin" && (
                      <>
                        <Button
                          onClick={() =>
                            handleClick(
                              "whats1_clicked",
                              currentOrder.client_phone1,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.primary}
                        >
                          <FaIcons.FaWhatsapp size={20} />
                          {currentOrder.whats1_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.whats1_clicked}
                            </Box>
                          )}
                        </Button>
                        <Button
                          onClick={() =>
                            handleClick(
                              "phone1_clicked",
                              currentOrder.client_phone1,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.blue}
                        >
                          <FaIcons.FaPhone size={20} />
                          {currentOrder.phone1_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.phone1_clicked}
                            </Box>
                          )}
                        </Button>
                        <Button
                          onClick={() =>
                            handleClick(
                              "sms1_clicked",
                              currentOrder.client_phone1,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.primary}
                        >
                          <FaIcons.FaSms size={20} />
                          {currentOrder.sms1_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.sms1_clicked}
                            </Box>
                          )}
                        </Button>
                      </>
                    )}
                  </span>
                </div>
              </SimpleGrid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  الملاحظه:
                  <span className="text-[#635BFF]">
                    {currentOrder.note || "لا يوجد ملاحظه"}
                  </span>
                </div>
                <div className="text-[#667085]">
                  رقم الهاتف الثاني:
                  <span className="text-[#635BFF]">
                    {currentOrder.client_phone2 || "-"}
                    {auth.user.role === "admin" && (
                      <>
                        <Button
                          onClick={() =>
                            handleClick(
                              "whats2_clicked",
                              currentOrder.client_phone2,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.primary}
                        >
                          <FaIcons.FaWhatsapp size={20} />
                          {currentOrder.whats2_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.whats2_clicked}
                            </Box>
                          )}
                        </Button>
                        <Button
                          onClick={() =>
                            handleClick(
                              "phone2_clicked",
                              currentOrder.client_phone2,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.blue}
                        >
                          <FaIcons.FaPhone size={20} />
                          {currentOrder.phone2_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.phone2_clicked}
                            </Box>
                          )}
                        </Button>
                        <Button
                          onClick={() =>
                            handleClick(
                              "sms2_clicked",
                              currentOrder.client_phone2,
                              currentOrder.serial_number
                            )
                          }
                          display="inline-block"
                          marginInline={2}
                          color={theme.primary}
                        >
                          <FaIcons.FaSms size={20} />
                          {currentOrder.sms2_clicked > 0 && (
                            <Box color={theme.error}>
                              {currentOrder.sms2_clicked}
                            </Box>
                          )}
                        </Button>
                      </>
                    )}
                  </span>
                </div>
              </SimpleGrid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  المحافظة/المدينة:
                  <span className="text-[#635BFF]">
                    {
                      governorates.find(
                        (gov) =>
                          +gov.id ===
                          currentOrder?.shipping_governorate?.governorate
                      )?.governorate_name_ar
                    }
                    /
                    {
                      cities.find((city) => +city.id === currentOrder?.city)
                        ?.city_name_ar
                    }
                  </span>
                </div>
                <div className="text-[#667085]">
                  العنوان :
                  <span className="text-[#635BFF]">
                    {currentOrder.client_address || "-"}
                  </span>
                </div>
              </SimpleGrid>
            </div>
            <div className="flex gap-4 text-2xl items-center p-4">
              <div className="rounded-full shadow-md bg-white p-3 flex items-center justify-center">
                <FiShoppingCart />
              </div>
              تفاصيل الطلب
            </div>
            <div className="rounded-xl border-[1px] bg-white border-[#DCDFE4]">
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  كود المسوق:{" "}
                  <span className="text-[#635BFF]">
                    {currentOrder.marketer?.code}
                  </span>
                </div>
                <div className="text-[#667085]">
                  اسم المسوق:{" "}
                  <span className="text-[#635BFF]">
                    {currentOrder.marketer?.name}
                  </span>
                </div>
              </SimpleGrid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  الصفحة:{" "}
                  <span className="text-[#635BFF]">
                    {currentOrder.page_name}
                  </span>
                </div>
                <div className="text-[#667085]">
                  كود الطلب:
                  <span className="text-[#635BFF]">
                    {currentOrder?.serial_number}
                  </span>
                </div>
              </SimpleGrid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  تاريخ الانشاء:{" "}
                  <span className="text-[#635BFF]">
                    {new Date(currentOrder?.created_at).toLocaleString()}
                  </span>
                </div>
                <div className="text-[#667085]">
                  اخر تحديث:
                  <span className="text-[#635BFF]">
                    {new Date(currentOrder?.updated_at).toLocaleString()}
                  </span>
                </div>
              </SimpleGrid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: 2, md: 6 }}
                className="items-center border-b-[1px] p-2 px-4 border-b-[#DCDFE4]"
              >
                <div className="text-[#667085]">
                  حالة الطلب:{" "}
                  <span className="text-[#635BFF]">
                    <Box
                      background={
                        currentOrder.status === "pending"
                          ? "yellow.400"
                          : currentOrder.status === "preparing"
                          ? "orange"
                          : currentOrder.status === "shipped"
                          ? "#0094B7"
                          : currentOrder.status === "available"
                          ? "green"
                          : "red"
                      }
                      display="inline-block"
                      as="span"
                      color={theme.light}
                      paddingInline={2}
                      paddingBlock={1}
                      borderRadius="5px"
                    >
                      {t(`pages.orders.${currentOrder.status}`)}
                    </Box>
                  </span>
                </div>
              </SimpleGrid>
            </div>
          </div>
          <Box className="mt-4 rounded-3xl shadow-lg border border-gray-200 bg-white p-6">
            {/* Header */}
            <Flex
              align="center"
              gap={4}
              p={4}
              mb={4}
              className="bg-gray-100 rounded-lg"
            >
              <Box className="bg-white p-3 rounded-full shadow-md">
                <Icon
                  as={MdOutlineProductionQuantityLimits}
                  boxSize={8}
                  color="gray.600"
                />
              </Box>
              <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                المنتجات
              </Text>
            </Flex>

            <Table variant="simple" size="sm" className="text-center">
              <Thead bg="gray.100">
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.orders.product")}</Th>
                  <Th>{t("pages.orders.price")}</Th>
                  <Th display={{ base: "none", md: "table-cell" }}>
                    {t("pages.orders.quantity")}
                  </Th>
                  <Th display={{ base: "none", md: "table-cell" }}>الخاصية</Th>
                  <Th>{t("pages.orders.products_price")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentOrder?.items?.map((el, index) => (
                  <Tr key={el._id} className="hover:bg-gray-50 transition">
                    <Td>{index + 1}</Td>
                    <Td>{el.product?.name}</Td>
                    <Td>{el.sale_price}</Td>
                    <Td display={{ base: "none", md: "table-cell" }}>
                      {el.qty}
                    </Td>
                    <Td display={{ base: "none", md: "table-cell" }}>
                      {
                        el.product.properties.find(
                          (prop) => prop._id === el.property
                        )?.key
                      }
                    </Td>
                    <Td>{el.total_sale_price}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <Divider my={6} />

            {/* Summary Section */}

            <Table variant="simple" size="sm" className="text-center">
              <Thead bg="gray.100">
                <Tr>
                  <Th>{t("pages.orders.shipping")}</Th>
                  <Th>{t("pages.orders.commission")}</Th>
                  <Th>{t("pages.orders.total")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="hover:bg-gray-50 transition">
                  <Td>{currentOrder?.shipping}</Td>
                  <Td>
                    {auth.user.role === "moderator"
                      ? "-"
                      : currentOrder?.commission}
                  </Td>
                  <Td>{currentOrder?.total}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </div>
        <div className="col-span-1 ">
          <div className="rounded-lg border-[1px] p-4 bg-white border-[#DCDFE4] ">
            <div className="flex gap-4 text-2xl items-center p-4">
              <div className="rounded-full shadow-md bg-white p-3 flex items-center justify-center">
                <MdOutlinePendingActions />
              </div>
              تتبع الطلب
            </div>
            <Flex direction="column" align="center" className="p-6 space-y-7">
              {currentOrder.actions
                .slice()
                .reverse()
                .map((el, index) => {
                  const StatusIcon = statusIcons[el.status] || FiClock; // Default icon if status doesn't match

                  return (
                    <React.Fragment key={el._id}>
                      <Flex
                        direction="column"
                        align="center"
                        className="p-4 rounded-lg shadow-lg bg-gray-800 relative w-60"
                      >
                        {/* Status Icon */}
                        <Box className="text-4xl text-blue-500">
                          <Icon as={StatusIcon} />
                        </Box>

                        {/* Status Label */}
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          mt={2}
                          color="white"
                        >
                          {t(`pages.orders.${el.status}`)}
                          {auth.user.role === "admin" && ` (${el.admin?.name})`}
                        </Text>

                        {/* Timestamp */}
                        <Text fontSize="sm" color="gray.400" mt={1}>
                          {new Date(el.created_at).toLocaleString()}
                        </Text>

                        {/* Arrow & Number for connecting steps */}

                        <Flex
                          direction="column"
                          align="center"
                          className="absolute bottom-[-30px]"
                        >
                          <FiArrowUpCircle className="text-2xl text-blue-500" />
                        </Flex>
                      </Flex>

                      {/* Connecting Line */}

                      <Box
                        height="8"
                        width="2"
                        bg="blue.500"
                        className="rounded-full my-2"
                      />
                    </React.Fragment>
                  );
                })}
              <Flex
                direction="column"
                align="center"
                className="p-4 rounded-lg shadow-lg bg-gray-800 relative w-60"
              >
                {/* Status Icon */}
                <Box className="text-4xl text-blue-500">
                  <Icon as={FiClock} />
                </Box>
                <Text fontSize="lg" fontWeight="semibold" mt={2} color="white">
                  {t(`pages.orders.pending`)}
                </Text>
                {/* Timestamp */}
                <Text fontSize="sm" color="gray.400" mt={1}>
                  {new Date(currentOrder.created_at).toLocaleString()}
                </Text>

                {/* Arrow & Number for connecting steps */}

                <Flex
                  direction="column"
                  align="center"
                  className="absolute bottom-[-30px]"
                ></Flex>
              </Flex>
            </Flex>
          </div>
        </div>
      </SimpleGrid>

      {auth.user.role === "admin" && (
        <Flex mt={8} justifyContent="center">
          <Button
            bg={theme.blue}
            color={theme.light}
            onClick={async () => {
              try {
                const { data } = await axios.get(
                  `/api/get_next_order/${currentOrder.created_at}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: Cookies.get("token"),
                    },
                  }
                );
                navigate(`/orders/order_details/${data.order._id}`);
              } catch (error) {
                console.log("something went wrong!");
              }
            }}
          >
            الاوردر السابق
          </Button>
        </Flex>
      )}

      {showUpdateOrder && (
        <UpdateModal
          data={showUpdateOrder}
          onClose={() => setShowUpdateOrder(false)}
        />
      )}

      {showUpdateOrdersStatus && (
        <UpdateOrdersStatusModal
          data={[currentOrder]}
          onClose={() => setShowUpdateOrdersStatus(false)}
        />
      )}
      {showOrderNotes && (
        <OrderNotes
          data={showOrderNotes}
          onClose={() => {
            setShowOrderNotes(false);
          }}
        />
      )}
      {showCancelOrdersStatus && (
        <CancelOrdersStatusModal
          data={[currentOrder]}
          onClose={() => setShowCancelOrdersStatus(false)}
        />
      )}
      {showReverseOrdersStatus && (
        <UpdateOrdersStatusModal
          data={[currentOrder]}
          reverse={true}
          onClose={() => setShowReverseOrdersStatus(false)}
        />
      )}
    </>
  );
};

export default OrderDetails;
