import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Spinner, useToast } from "@chakra-ui/react";

import Sidebar from "../../components/layout/sidebar/Sidebar";
import Navbar from "../../components/layout/navbar/Navbar";
import Footer from "../../components/layout/footer/Footer";

import { checkAuthentication } from "../../store/auth/authSlice";
import { registerToken } from "../../store/users/usersSlice";

import { AppWrapper } from "../../AppStyle";
import { toggleSidebar } from "../../store/sidebar/sidebarSlice";
import { messaging } from "../../utilities/firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";

const ProtectedRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const sidebar = useSelector((state) => state.sidebar);
  const toast = useToast({
    position: "top",
    duration: 5000,
    status: "success",
  });
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!sidebar.isNotOpened && window.location.pathname.includes("/orders")) {
      dispatch(toggleSidebar());
    }
  }, []);
  const requestPermissionAndGenerateToken = async () => {
    try {
      // Request permission for notifications
      const permission = await Notification.requestPermission();
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BGBkJqQR6rzYgNQb3bTDxuzgBp3Li4Z5RSFkWxu_Q3Pr0H8q6UxJkvQNl72I9v37ta77-HhkykD6pTysdc9GD94",
      });

      if (fcmToken) {
        console.log("FCM Token:", fcmToken);
        dispatch(registerToken({ fcmToken }));
      } else {
        console.log("Failed to get FCM token");
      }
      if (permission === "granted") {
        // Generate FCM token
      } else {
        console.log("Permission not granted for notifications");
      }
    } catch (error) {
      console.error("An error occurred while retrieving the FCM token:", error);
    }
  };
  useEffect(() => {
    const addNewMessage = (event) => {
      const payload = event.data;
      toast({
        description: payload?.notification.body,
      });
    };

    dispatch(checkAuthentication())
      .unwrap()
      .then((_) => {
        setLoaded((prev) => !prev);
        requestPermissionAndGenerateToken();
        navigator.serviceWorker.addEventListener("message", addNewMessage);
      })
      .catch((_) => {
        setLoaded((prev) => !prev);
      });
    return () =>
      navigator.serviceWorker.removeEventListener("message", addNewMessage);
  }, [dispatch]);

  return loaded ? (
    auth.isAuth ? (
      <AppWrapper>
        <Sidebar />
        <Box
          className={`content-wrapper ${sidebar.isNotOpened ? "active" : ""}`}
        >
          <Box className="content">
            <Navbar />
            <Box className="app-content">
              <Outlet />
            </Box>
          </Box>
          <Footer />
        </Box>
      </AppWrapper>
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Spinner />
  );
};

export default ProtectedRoutes;
