import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Text, Alert, AlertIcon } from '@chakra-ui/react';

import Pagination from '../../shared/pagination/Pagination';
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';
import MyProductsTable from './MyProductsTable';

import { getProducts } from '../../../store/products/productsSlice';

import { MyProductsWrapper } from './MyProductsStyle';

import theme from '../../global/theme';

const MyProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(state => state.products);
  const auth = useSelector(state => state.auth);

  const [page, setPage] = useState(1);
  const [query] = useState("");
  const defaultFilter = { merchant: auth.user._id };
  const [filter] = useState(JSON.stringify(defaultFilter));

  useEffect(() => {
    dispatch(getProducts({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <MyProductsWrapper>

      <Breadcrumbs currentPage={t('pages.products.products')} pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]} />

      {products.errors.length > 0 && <Alert status="error" variant="left-accent" marginBottom={8}>
        <AlertIcon />
        {products.errors?.map((error, index) => (
          <Text key={index}>{error?.msg}</Text>
        ))}
      </Alert>}

      {products.data?.itemsCount > 0 ? (
        <MyProductsTable
          data={products.data.data}
          page={page}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.products.no_products')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={products.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

    </MyProductsWrapper>
  )
}

export default MyProducts