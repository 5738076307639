import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../shared/table/Table";
import { useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { Button } from "@chakra-ui/react";
const MarktersAccountsTable = ({
  data,
  page,
  setShowUpdateAccount,
  setAccount,
}) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.accounts.marketer")}</th>
          <th>الكود</th>
          <th>رقم الهاتف</th>
          <th>{t("pages.accounts.pending")}</th>
          <th>{t("pages.accounts.preparing")}</th>
          <th>{t("pages.accounts.shipped")}</th>
          <th>{t("pages.accounts.available")}</th>
          {auth.user?.rule?.name === "superadmin" && <th>تعديل الأرصدة</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            <td>{el.marketer?.name}</td>
            <td>{el.marketer?.code}</td>
            <td>{el.marketer?.phone}</td>
            <td>{el.pending}</td>
            <td>{el.preparing}</td>
            <td>{el.shipped}</td>
            <td>{el.available}</td>
            {auth.user?.rule?.name === "superadmin" && (
              <td>
                <Button
                  bg="transparent"
                  color="red"
                  size="xs"
                  onClick={() => {
                    setShowUpdateAccount(true);
                    setAccount(el);
                  }}
                >
                  <FiEdit size={20} />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MarktersAccountsTable;
