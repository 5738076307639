import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getImages,
  createImage,
  updateImage,
  deleteImage,
} from "../../../store/images/imageSlice";

const ImageManager = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const {
    data: images,
    isLoading,
    errors,
  } = useSelector((state) => state.images);
  const { register, handleSubmit, reset, control, setValue } = useForm();

  useEffect(() => {
    dispatch(getImages());
  }, [dispatch]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("relatedUrl", data.relatedUrl);

    try {
      if (selectedImage) {
        // Update existing image
        await dispatch(
          updateImage({ id: selectedImage._id, formData })
        ).unwrap();
      } else {
        // Create new image
        await dispatch(createImage(formData)).unwrap();
      }
      reset();
      setImageFile(null);
      setSelectedImage(null);
      dispatch(getImages()); // Refresh the image list
      onClose(); // Close the modal after success
    } catch (error) {
      console.error("فشل في رفع الصورة:", error);
    }
  };

  const handleEdit = (image) => {
    setSelectedImage(image);
    setImageFile(null);
    setValue("relatedUrl", image.relatedUrl); // Set the existing related URL
    onOpen(); // Open the modal for editing
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteImage(id)).unwrap();
    } catch (error) {
      console.error("فشل في حذف الصورة:", error);
    }
  };

  return (
    <div className="container p-4 mx-auto">
      <Button colorScheme="blue" onClick={onOpen}>
        إضافة صورة
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent dir="rtl">
          {" "}
          {/* Set direction to RTL */}
          <ModalHeader>
            {selectedImage ? "تعديل الصورة" : "إضافة صورة"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <Input
                    type="file"
                    onChange={(e) => {
                      setImageFile(e.target.files[0]);
                      field.onChange(e);
                    }}
                    className="block w-full mb-4"
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                name="relatedUrl"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="رابط "
                    {...field}
                    className="block w-full mb-4"
                  />
                )}
                rules={{ required: true }}
              />
              <Button type="submit" colorScheme="blue">
                {selectedImage ? "تحديث الصورة" : "تحميل الصورة"}
              </Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              إغلاق
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isLoading && <p>جاري التحميل...</p>}
      {errors.length > 0 && (
        <p className="text-red-500">
          خطأ: {errors.map((e) => e.msg).join(", ")}
        </p>
      )}

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {images.map((image) => (
          <div key={image._id} className="p-4 border rounded shadow">
            <img
              src={`/settings/${image.image}`}
              alt="صورة"
              className="object-cover w-full h-32 mb-4"
            />
            <p className="mb-2 text-gray-700">الرابط : {image.relatedUrl}</p>
            <Button
              onClick={() => handleEdit(image)}
              colorScheme="yellow"
              className="mr-2 me-2"
            >
              تعديل
            </Button>
            <Button onClick={() => handleDelete(image._id)} colorScheme="red">
              حذف
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageManager;
