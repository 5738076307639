import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  dialyOrdersStatistics,
  getOrderStatistics,
} from "../../../store/reports/reportsSlice";
import { Flex, Box, Heading, Spinner, Text } from "@chakra-ui/react";
import OrderStatistics from "../statistics/OrderStatistics";

// Registering Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(dialyOrdersStatistics({})).unwrap();
        await dispatch(getOrderStatistics({})).unwrap();
      } catch (error) {
        console.error("Failed to fetch daily orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const dailyOrders = reports.data?.dailyOrders || [];

  // Check if there are no orders
  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  // Chart data preparation
  const chartData = {
    labels: dailyOrders?.map((order) => order._id.day),
    datasets: [
      {
        label: "عدد الطلبات",
        data: dailyOrders?.map((order) => order.totalOrders),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "الطلبات اليومية",
      },
    },
  };

  return (
    <Flex direction="column" w="100%">
      <OrderStatistics data={reports?.data?.orders} />

      <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
        الطلبات
      </Heading>
      <Box p={4}>
        <Bar data={chartData} options={chartOptions} />
      </Box>
    </Flex>
  );
};

export default ChartComponent;
