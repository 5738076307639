import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatisticsMarketers,
  getProductRates,
  clearActivities,
  getOrderStatistics,
} from "../../../store/reports/reportsSlice";
import {
  Flex,
  Box,
  Heading,
  Select,
  useBreakpointValue,
  SimpleGrid,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import DataTable, {
  ActivityTable,
  AdminDataTable,
  MarketersDataTable,
} from "./DataTable";
import Filter from "./Filter";

import ActivitiesTabs from "./ActivitiesList";
import OrderStatistics from "./OrderStatistics";
import Pagination from "../../shared/pagination/Pagination";
import { FaUser, FaDollarSign, FaCogs } from "react-icons/fa";

const ChartComponent = () => {
  const [additionalParams, setAdditionalParams] = useState({
    filterType: "day",
  });
  const columns = useBreakpointValue({ base: 2, lg: 4 });
  const dispatch = useDispatch();
  const [view, setView] = useState("marketers");
  const [page, setPage] = useState(1);
  const reports = useSelector((state) => state.reports);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (view === "admin") {
      dispatch(clearActivities());
    } else if (view === "orders") {
      dispatch(getOrderStatistics({}));
    }
    setPage(1);
  }, [view]);

  return (
    <Flex direction="column" w="100%">
      <Tabs
        variant="enclosed"
        isLazydefaultIndex={0}
        onChange={(index) => {
          // Update the view based on the tab index
          const viewOptions = ["marketers", "rates", "orders"];
          setView(viewOptions[index]);
        }}
      >
        <TabList
          mb="4"
          gridTemplateColumns={`repeat(${columns}, 1fr)`}
          display="grid"
          gap={{ base: 3, lg: 4 }}
        >
          <Tab p={{ base: 1, lg: 2 }}>
            <FaUser style={{ margin: "0px 15px" }} />
            المسوقون
          </Tab>
          <Tab p={{ base: 1, lg: 2 }}>
            <FaDollarSign style={{ margin: "0px 15px" }} />
            معدلات السحب
          </Tab>
          <Tab p={{ base: 1, lg: 2 }}>
            <FaDollarSign style={{ margin: "0px 15px" }} />
            الطلبات
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {view === "marketers" && (
              <>
                <Filter
                  handleFilter={(values) => {
                    setAdditionalParams({ ...additionalParams, ...values });
                    dispatch(
                      getStatisticsMarketers({
                        filter: { ...additionalParams, ...values },
                      })
                    );
                  }}
                  handleClearFilter={() => {
                    setAdditionalParams({ filterType: "day" });
                  }}
                  disablesBtns={reports.isLoading}
                />
                <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                  إحصائيات المسوقون
                </Heading>
                <MarketersDataTable
                  data={reports?.data?.marketers?.mostPopularMarketers || []}
                  diviedBy={1}
                />
                <Flex justifyContent="flex-end">
                  <Pagination
                    page={page}
                    size={20}
                    itemsCount={reports?.data?.marketers?.totalPage ?? 0}
                    onChange={(page) => {
                      setPage(page);
                      dispatch(
                        getStatisticsMarketers({
                          filter: { ...additionalParams },
                          page,
                        })
                      );
                    }}
                  />
                </Flex>
              </>
            )}
          </TabPanel>
          <TabPanel>
            {view === "rates" && (
              <>
                <Filter
                  view={"rates"}
                  handleFilter={(values) => {
                    setAdditionalParams({ ...additionalParams, ...values });
                    dispatch(
                      getProductRates({
                        filter: { ...additionalParams, ...values },
                      })
                    );
                  }}
                  handleClearFilter={() => {
                    setAdditionalParams({ filterType: "day" });
                  }}
                  disablesBtns={reports.isLoading}
                />
                <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                  معدلات السحب للمنتجات
                </Heading>
                <DataTable
                  status={additionalParams?.status || "skip"}
                  data={reports?.data?.rates?.mostPopularProducts || []}
                  diviedBy={1}
                />
                <Flex justifyContent="flex-end">
                  <Pagination
                    page={page}
                    size={20}
                    itemsCount={reports?.data?.rates?.totalPage ?? 0}
                    onChange={(page) => {
                      setPage(page);

                      dispatch(
                        getProductRates({
                          filter: { ...additionalParams },
                          page,
                        })
                      );
                    }}
                  />
                </Flex>
              </>
            )}
          </TabPanel>
          <TabPanel>
            {view === "orders" && (
              <OrderStatistics data={reports?.data?.orders} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default ChartComponent;
