import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, Button, Flex } from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';

import theme from '../../global/theme';


const InputSearch = ({ isLoading, handleSearch }) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  return (
    <Flex>
      <Button
        type="button" me={4} bg={theme.blue} color={theme.light} isLoading={isLoading}
        onClick={() => handleSearch(inputRef.current.value)}
      >
        <FiIcons.FiSearch />
      </Button>
      <InputGroup width="auto">
        <Input ref={inputRef} type="search" placeholder={t('general.search')}
          bg={theme.light} color={theme.dark} border="none"
        />
      </InputGroup>
    </Flex>
  )
}

export default InputSearch