import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Flex,
  Switch,
} from "@chakra-ui/react";
import { FaFilter, FaUndo } from "react-icons/fa"; // Importing icons from react-icons

import Search from "../../../shared/search/Search";

import { getShippingCompanies } from "../../../../store/shippingCompanies/shippingCompaniesSlice";
import { getWarehouses } from "../../../../store/warehouses/warehousesSlice";
import { getPriceList } from "../../../../store/priceList/priceListSlice";

import theme from "../../../global/theme";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue, watch } = useForm();

  watch(["shipping_company", "shipping_governorate", "warehouse", "is_late"]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const shippingCompanies = useSelector((state) => state.shippingCompanies);
  const priceList = useSelector((state) => state.priceList);
  const warehouses = useSelector((state) => state.warehouses);

  const [priceListPage, setPriceListPage] = useState(1);
  const [priceListQuery, setPriceListQuery] = useState("");
  const [priceListFilter] = useState(JSON.stringify({ is_active: true }));
  useEffect(() => {
    dispatch(
      getPriceList({
        page: priceListPage,
        size: 50,
        query: priceListQuery,
        filter: priceListFilter,
      })
    );
  }, [dispatch, priceListPage, priceListQuery, priceListFilter]);

  const [shippingCompaniesPage, setShippingCompaniesPage] = useState(1);
  const [shippingCompaniesQuery, setShippingCompaniesQuery] = useState("");
  useEffect(() => {
    dispatch(
      getShippingCompanies({
        page: shippingCompaniesPage,
        query: shippingCompaniesQuery,
      })
    );
  }, [dispatch, shippingCompaniesPage, shippingCompaniesQuery]);

  const [warehousesPage, setWarehousesPage] = useState(1);
  const [warehousesQuery, setWarehousesQuery] = useState("");
  useEffect(() => {
    dispatch(getWarehouses({ page: warehousesPage, query: warehousesQuery }));
  }, [dispatch, warehousesPage, warehousesQuery]);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};

        for (let prop in values) {
          if (values[prop]) {
            if (prop === "shipping_governorate") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "warehouse") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "shipping_company") {
              finalValues[prop] = values[prop]?._id;
            } else {
              finalValues[prop] = values[prop];
            }
          } else if (prop === "is_late") {
            if (values.is_late) {
              finalValues.is_late = true;
            } else {
              finalValues.is_late = false;
            }
          }
        }

        handleFilter(finalValues);
      })}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 4 }}
        spacing={6}
        bg="#eee"
        p={2}
      >
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("general.from")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("to")}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("pages.orders.shipping_governorate")}
          </FormLabel>
          <Search
            allowSearch={false}
            allowPagination={false}
            page={priceListPage}
            itemsCount={priceList.data?.itemsCount}
            data={priceList.data?.data}
            type="fromGovernorate"
            prop="governorate"
            currentElement={getValues("shipping_governorate")}
            handleChangePage={(page) => {
              setPriceListPage(page);
            }}
            handleChangeQuery={(query) => {
              setPriceListQuery(query);
            }}
            handleSelected={(item) => {
              setValue("shipping_governorate", item);
              setValue("city", "");
            }}
          />
        </FormControl>

        {auth.user.role === "admin" && (
          <>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                {t("pages.orders.shipping_company")}
              </FormLabel>
              <Search
                page={shippingCompaniesPage}
                itemsCount={shippingCompanies.data?.itemsCount}
                data={shippingCompanies.data?.data}
                prop="name"
                currentElement={getValues("shipping_company")}
                handleChangePage={(page) => {
                  setShippingCompaniesPage(page);
                }}
                handleChangeQuery={(query) => {
                  setShippingCompaniesQuery(query);
                }}
                handleSelected={(item) => setValue("shipping_company", item)}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                المخزن
              </FormLabel>
              <Search
                page={warehousesPage}
                itemsCount={warehouses.data?.itemsCount}
                data={warehouses.data?.data}
                prop="name"
                currentElement={getValues("warehouse")}
                handleChangePage={(page) => {
                  setWarehousesPage(page);
                }}
                handleChangeQuery={(query) => {
                  setWarehousesQuery(query);
                }}
                handleSelected={(item) => setValue("warehouse", item)}
              />
            </FormControl>
          </>
        )}
        {auth.user.role === "admin" && (
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" gap={2}>
              <FormLabel m={0} fontWeight="bold" textTransform="capitalize">
                الطلبات المتأخرة
              </FormLabel>
              <Switch
                colorScheme="teal"
                size="lg"
                type="checkbox"
                color={theme.light}
                {...register("is_late")}
              />
            </Flex>
          </FormControl>
        )}
        <Flex alignItems="flex-end">
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="blue"
            me={2}
            leftIcon={<FaFilter />}
          >
            فلتر
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              setValue("warehouse", "");
              setValue("shipping_company", "");
              setValue("shipping_governorate", "");
              setValue("city", "");
              setValue("product", "");
              setValue("moderator", "");
              setValue("updatedFrom", "");
              setValue("updatedTo", "");
              setValue("from", "");
              setValue("to", "");
              setValue("marketer", "");
              setValue("reason_category", "");
              setValue("is_late", false);
            }}
            leftIcon={<FaUndo />}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
};

export default Filter;
