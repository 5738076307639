import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Divider,
  Box,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import generator from "../../../../utilities/generator";

import Search from "../../../shared/search/Search";

import {
  createProduct,
  getProducts,
} from "../../../../store/products/productsSlice";
import { getCategories } from "../../../../store/categories/categoriesSlice";
import { getUsers } from "../../../../store/users/usersSlice";
import { getWarehouses } from "../../../../store/warehouses/warehousesSlice";

import theme from "../../../global/theme";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const users = useSelector((state) => state.users);
  const warehouses = useSelector((state) => state.warehouses);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  watch(["category", "merchant", "properties", "warehouse"]);

  const {
    fields: properties,
    append: appendProperty,
    remove: removeProperty,
  } = useFieldArray({
    name: "properties",
    control,
  });

  const [categoriesPage, setCategoriesPage] = useState(1);
  const [categoriesQuery, setCategoriesQuery] = useState("");

  const [warehousesPage, setWarehousesPage] = useState(1);
  const [warehousesQuery, setWarehousesQuery] = useState("");

  const [merchantsPage, setVendorsPage] = useState(1);
  const [merchantsQuery, setVendorsQuery] = useState("");

  useEffect(() => {
    dispatch(getCategories({ page: categoriesPage, query: categoriesQuery }));
  }, [dispatch, categoriesPage, categoriesQuery]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: merchantsPage,
        query: merchantsQuery,
        filter: JSON.stringify({ role: "merchant" }),
      })
    );
  }, [dispatch, merchantsPage, merchantsQuery]);

  useEffect(() => {
    dispatch(getWarehouses({ page: warehousesPage, query: warehousesQuery }));
  }, [dispatch, warehousesPage, warehousesQuery]);
  const [productsPage, setProductsPage] = useState(1);
  const [productsQuery, setProductsQuery] = useState("");
  const productsDefaultFilter = { is_active: true };
  const [productsFilter] = useState(JSON.stringify(productsDefaultFilter));
  useEffect(() => {
    dispatch(
      getProducts({
        page: productsPage,
        query: productsQuery,
        filter: productsFilter,
        is_safka: "yes",
      })
    );
  }, [dispatch, productsPage, productsQuery, productsFilter]);

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            const formData = new FormData(event.target);

            const allData = [...formData];

            if (values.merchant) {
              formData.append("merchant", values.merchant._id);
            }

            if (values._id) {
              formData.append("_id", values._id);
            }

            if (values.category) {
              formData.append("category", values.category._id);
            }

            if (values.warehouse) {
              formData.append("warehouse", values.warehouse._id);
            }

            if (values.is_active) {
              formData.set("is_active", true);
            }
            if (values.is_popular) {
              formData.set("is_popular", true);
            }

            for (let i = 0; i < allData.length; i++) {
              let key = allData[i][0];
              if (key.startsWith("properties")) {
                formData.delete(key);
              }
            }

            formData.append("properties", JSON.stringify(values.properties));

            dispatch(createProduct(formData))
              .unwrap()
              .then((res) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("pages.products.create_product")}
          </ModalHeader>
          <ModalBody>
            {products.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {products.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  color="white"
                  textTransform="capitalize"
                >
                  نسخ من
                </FormLabel>
                <Search
                  page={productsPage}
                  itemsCount={products.data?.itemsCount}
                  data={products.data?.data}
                  prop="name"
                  currentElement={getValues(`product`)}
                  handleChangePage={(page) => {
                    setProductsPage(page);
                  }}
                  handleChangeQuery={(query) => {
                    setProductsQuery(query);
                  }}
                  handleSelected={(item) => {
                    setValue("product", item); // Store the selected product

                    // Auto-fill fields with the selected product's details
                    setValue("name", item.name);
                    setValue("barcode", item.barcode);
                    setValue("category", item.category);
                    setValue("purchase_price", item.purchase_price);
                    setValue("sale_price", item.sale_price);
                    setValue("media_url", item.media_url);
                    setValue("merchant", item.merchant);
                    setValue("warehouse", item.warehouse);
                    setValue("is_active", item.is_active);
                    setValue("is_upsell", item.is_upsell);
                    setValue("is_archived", item.is_archived);
                    setValue("upsell_message", item.upsell_message);
                    setValue("note", item.note);
                    setValue("description", item.description);
                    setValue("properties", item.properties);
                    setValue("_id", item._id);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                  m={0}
                >
                  الأكثر مبيعاً
                </FormLabel>
                <Flex alignItems="center">
                  <input
                    type="checkbox"
                    color={theme.light}
                    {...register("is_popular")}
                  />
                  <Box color={theme.light}>الأكثر مبيعاً</Box>
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  التاجر
                </FormLabel>
                <Search
                  page={merchantsPage}
                  itemsCount={users.data?.itemsCount}
                  data={users.data?.data}
                  prop="name"
                  currentElement={getValues("merchant")}
                  handleChangePage={(page) => {
                    setVendorsPage(page);
                  }}
                  handleChangeQuery={(query) => {
                    setVendorsQuery(query);
                  }}
                  handleSelected={(item) => {
                    setValue("merchant", item);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.warehouse")}
                </FormLabel>
                <Search
                  page={warehousesPage}
                  itemsCount={warehouses.data?.itemsCount}
                  data={warehouses.data?.data}
                  prop="name"
                  currentElement={getValues("warehouse")}
                  handleChangePage={(page) => {
                    setWarehousesPage(page);
                  }}
                  handleChangeQuery={(query) => {
                    setWarehousesQuery(query);
                  }}
                  handleSelected={(item) => {
                    setValue("warehouse", item);
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.name")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.products.name")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.barcode")}
                </FormLabel>
                <Flex>
                  <Input
                    type="text"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    borderRadius={4}
                    placeholder={t("pages.products.barcode")}
                    _placeholder={{ textTransform: "capitalize" }}
                    {...register("barcode", {
                      required: `${t("validation.required")}`,
                    })}
                  />
                  <Button
                    type="button"
                    bg="#F78B00"
                    _hover={{ bg: "#F78B00" }}
                    marginStart={2}
                    onClick={() => setValue("barcode", generator())}
                  >
                    <FiIcons.FiCode />
                  </Button>
                </Flex>
                {errors.barcode?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.barcode.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.category")}
                </FormLabel>
                <Search
                  page={categoriesPage}
                  itemsCount={categories.data?.itemsCount}
                  data={categories.data?.data}
                  prop="name"
                  currentElement={getValues("category")}
                  handleChangePage={(page) => {
                    setCategoriesPage(page);
                  }}
                  handleChangeQuery={(query) => {
                    setCategoriesQuery(query);
                  }}
                  handleSelected={(item) => {
                    setValue("category", item);
                  }}
                />
                {errors.category?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.category.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.purchase_price")}
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.products.purchase_price")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("purchase_price", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.purchase_price?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.purchase_price.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.sale_price")}
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.products.sale_price")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("sale_price", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.sale_price?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.sale_price.message}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  عمولة صفقة
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  // placeholder={t("pages.products.sale_price")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("extra_cost", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.extra_cost?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.extra_cost.message}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  العمولة المقترحة
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  // placeholder={t("pages.products.sale_price")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("commission")}
                />
                {errors.commission?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.commission.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.image")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type="file"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    height="auto"
                    padding=".4rem 1rem"
                    _placeholder={{ textTransform: "capitalize" }}
                    {...register("image")}
                  />
                  <InputRightElement
                    color={theme.light}
                    children={<FiIcons.FiImage />}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.media_url")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.products.media_url")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("media_url", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.media_url?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.media_url.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.products.is_active")}
                </FormLabel>
                <Flex alignItems="center">
                  <input
                    type="checkbox"
                    color={theme.light}
                    {...register("is_active")}
                  />
                  <Box ms={2} color={theme.light}>
                    {t("pages.products.is_active")}
                  </Box>
                </Flex>
              </FormControl>
            </SimpleGrid>

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.products.note")}
              </FormLabel>
              <Input
                type="text"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder={t("pages.products.note")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("note")}
              />
              {errors.note?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.note.message}
                </Text>
              )}
            </FormControl>

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                {t("pages.products.description")}
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder={t("pages.products.description")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("description")}
              />
              {errors.description?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>

            <Divider marginBlock={6} />
            {properties.length === 0 ? (
              <Button type="button" onClick={() => appendProperty({})}>
                إضافة خاصية
              </Button>
            ) : (
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 3 }}
                border={`1px solid ${theme.border}`}
                alignItems="center"
                paddingBlock={2}
                mb={2}
                borderRadius={4}
              >
                {properties.map((el, index) => (
                  <React.Fragment key={el.id}>
                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="200px"
                        {...register(`properties.${index}.key`)}
                        placeholder="مثال: لون احمر مقاس لارج"
                      />
                    </Box>
                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="200px"
                        {...register(`properties.${index}.value`)}
                        placeholder="اكتب كمية مثلا: 5"
                      />
                    </Box>
                    <Box marginInline={5} mb={3}>
                      <Button
                        type="button"
                        bg={theme.success}
                        color={theme.light}
                        onClick={() => appendProperty({})}
                        me={2}
                      >
                        <FiIcons.FiPlus />
                      </Button>
                      <Button
                        type="button"
                        bg={theme.error}
                        color={theme.light}
                        onClick={() => removeProperty(index)}
                      >
                        <FiIcons.FiDelete />
                      </Button>
                    </Box>
                  </React.Fragment>
                ))}
              </SimpleGrid>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={products.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={products.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
