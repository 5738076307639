import styled from "styled-components";

export const TableWrapper = styled.section`
  table {
    background: ${({ theme }) => theme.light};
    ${({ pathname }) =>
      pathname === "/orders" &&
      `
        transform: rotateX(180deg);
        font-size: 10px;
      `};

    thead {
      tr {
        th {
          padding: 0.8rem 0.5rem;
          color: ${({ theme }) => theme.dark};
          text-transform: ${({ headTextTransform }) =>
            headTextTransform || "capitalize"};
          border-bottom: 2px solid ${({ theme }) => theme.border};
        }
      }
    }
    tbody {
      tr {
        border-bottom: 2px solid ${({ theme }) => theme.border};
        &:last-child {
          border-bottom: 0;
        }
        td {
          text-align: center;
          font-weight: bold;
          padding-block: 0.8rem;
          font-size: 14px;
          padding-inline: 0.3rem;
          white-space: normal;
          color: ${({ theme }) => theme.dark};
          border-right: 1px solid ${({ theme }) => theme.border};
        }
      }
    }
  }
`;
