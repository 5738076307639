import React from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi"; // Import only the needed icon
import { useSelector } from "react-redux";
import { governorates } from "../../../utilities/places";

const PriceListTable = ({ data, page, handleUpdate }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full overflow-hidden bg-white rounded-lg shadow-md">
        <thead className="bg-gray-100 border-b">
          <tr>
            <th className="px-4 py-2 text-sm font-medium tracking-wider text-center text-gray-500 uppercase">
              #
            </th>
            <th className="px-4 py-2 text-sm font-medium tracking-wider text-center text-gray-500 uppercase">
              {t("pages.price_list.governorate")}
            </th>
            <th className="px-4 py-2 text-sm font-medium tracking-wider text-center text-gray-500 uppercase">
              {t("pages.price_list.price")}
            </th>
            {auth.user.role === "admin" && (
              <th className="px-4 py-2 text-sm font-medium tracking-wider text-center text-gray-500 uppercase">
                {t("general.action")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((el, index) => (
            <tr key={el._id} className="border-b hover:bg-gray-50">
              <td className="px-4 py-2 text-sm text-center text-gray-700 ">
                {(page - 1) * 10 + (index + 1)}
              </td>
              <td className="px-4 py-2 text-sm text-center text-gray-700 ">
                {
                  governorates.find((gov) => +gov.id === el.governorate)
                    ?.governorate_name_ar
                }
              </td>
              <td className="px-4 py-2 text-sm text-center text-gray-700 ">
                {el.price}
              </td>
              {auth.user.role === "admin" && (
                <td className="px-4 py-2 text-sm text-center ">
                  <button
                    className="text-green-600 transition-colors duration-200 hover:text-green-800"
                    onClick={() => handleUpdate(el)}
                  >
                    <FiEdit size={20} />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceListTable;
