import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertIcon,
  Text,
  SimpleGrid,
  Flex,
  Button,
} from "@chakra-ui/react";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import Pagination from "../../shared/pagination/Pagination";
import PriceListTable from "./PriceListTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import Filter from "./actions/Filter";

import {
  getPriceList,
  clonsePriceList,
} from "../../../store/priceList/priceListSlice";

import { PriceListWrapper } from "./PriceListStyle";

import theme from "../../global/theme";

const ShippngCompanies = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const priceList = useSelector((state) => state.priceList);
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [query] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreatePriceList, setShowCreatePriceList] = useState(false);
  const [showUpdatePriceList, setShowUpdatePriceList] = useState(false);

  useEffect(() => {
    dispatch(getPriceList({ page, query, filter }));
  }, [dispatch, page, query, filter]);
  const cloneFromSafka = () => {
    dispatch(clonsePriceList({}));
    dispatch(getPriceList({ page, query, filter }));
  };
  return (
    <PriceListWrapper>
      <Breadcrumbs
        currentPage={t("pages.price_list.price_list")}
        pages={[{ name: t("pages.dashboard.dashboard"), path: "/" }]}
      />

      <Alert bg={theme.primary} color={theme.light} mb={4} fontSize={22}>
        يتم التوصيل في مدة تترواح بين يومين الي خمسه أيام من خروج الطلب للشحن
        حسب المحافظه
      </Alert>

      {priceList.errors?.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {priceList.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {auth.user.role === "admin" && (
        <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
          <Flex marginBottom={8} gap={4}>
            <Button
              bg="red.600"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize={18}
              marginInlineEnd={4}
              color="white"
              _hover={{ bg: "red.600" }}
              onClick={() => setShowCreatePriceList(true)}
            >
              {t("general.create")}
            </Button>
            <Button
              bg="red.600"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize={18}
              marginInlineEnd={4}
              color="white"
              _hover={{ bg: "red.600" }}
              onClick={() => cloneFromSafka()}
            >
              نسخ
            </Button>
          </Flex>

          <Flex marginBottom={8} justifyContent="flex-end">
            <InputSearch
              isLoading={priceList.isLoading}
              handleSearch={(query) => {}}
            />

            <Filter
              handleFilter={(values) => setFilter(values)}
              handleClearFilter={() => setFilter("{}")}
              disablesBtns={priceList.isLoading}
            />
          </Flex>
        </SimpleGrid>
      )}

      {priceList.data?.itemsCount > 0 ? (
        <PriceListTable
          data={priceList.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdatePriceList(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={12}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color={theme.text}
            fontWeight="bold"
          >
            {t("pages.price_list.no_price_list")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={priceList.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreatePriceList && (
        <CreateModal onClose={() => setShowCreatePriceList(false)} />
      )}
      {showUpdatePriceList && (
        <UpdateModal
          data={showUpdatePriceList}
          onClose={() => setShowUpdatePriceList(false)}
        />
      )}
    </PriceListWrapper>
  );
};

export default ShippngCompanies;
