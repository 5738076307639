import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

let initialState = {
  isLoading: false,
  data: {},
  user: null,
  errors: [],
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/get_users?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_user", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(`/api/update_user/${args._id}`, args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const openUser = createAsyncThunk(
  "users/openUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/open_user/${args._id}`,
        {},
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "users/updateUserPassword",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_user_password/${args.id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);
export const registerToken = createAsyncThunk(
  "users/registerToken",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/api/register-fcm-token`, args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    // Handle getUsers
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })

      // Handle createUser
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount++;
        if (state.data?.data?.length < 10) {
          state.data.data.push(action.payload.data);
        }
        state.errors = [];
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateUser
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        const indexAt = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (indexAt !== -1) {
          state.data.data[indexAt] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle openUser (login)
      .addCase(openUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(openUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
        Cookies.set("token", action.payload.token, {
          expires: 1, // 1 day
        });
      })
      .addCase(openUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateUserPassword
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle registerToken
      .addCase(registerToken.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(registerToken.fulfilled, (state) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(registerToken.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      });
  },
});

export default usersSlice.reducer;
