import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Flex,
  Select,
} from "@chakra-ui/react";
import theme from "../../global/theme";
import Search from "../../shared/search/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUsers } from "../../../store/users/usersSlice";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns, view }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, getValues, watch } = useForm();
  const [filterType, setFilterType] = useState("day");
  watch(["admin", "from", "to", "filterType"]);
  const admins = useSelector((state) => state.users);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(JSON.stringify({ role: "admin", is_active: true }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);
  return (
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};

        for (let prop in values) {
          if (values[prop]) {
            if (prop === "from" || prop === "to") {
              finalValues[prop] = new Date(values[prop]);
            } else if (prop === "admin") {
              finalValues[prop] = values[prop]?._id;
            } else {
              finalValues[prop] = values[prop];
            }
          }
        }

        handleFilter(finalValues);
      })}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 4 }}
        spacing={6}
        bg="#eee"
        p={2}
      >
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            نوع الفلتر
          </FormLabel>
          <Select
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            {...register("filterType")}
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value); // Update the filterType state
              setValue("filterType", e.target.value);
              setValue("from", "");
              setValue("to", "");
            }}
          >
            <option value="day">يومي</option>
            <option value="week">أسبوعي</option>
            <option value="month">شهري</option>
            <option value="other"> نطاق زمني</option>
          </Select>
        </FormControl>
        {view == "adminStatics" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              القسم
            </FormLabel>
            <Select
              bg={theme.bg}
              color={theme.dark}
              border="none"
              borderRadius={4}
              {...register("department")}
            >
              <option value="All">الكل</option>
              <option value="cs.">CS</option>
              <option value="co.">CO</option>
            </Select>
          </FormControl>
        )}
        {filterType === "other" && (
          <>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                (من)تاريخ الإنشاء
              </FormLabel>
              <Input
                type="date"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder="من"
                _placeholder={{ textTransform: "capitalize" }}
                {...register("from")}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                إلى
              </FormLabel>
              <Input
                type="date"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder="إلى"
                _placeholder={{ textTransform: "capitalize" }}
                {...register("to")}
              />
            </FormControl>
          </>
        )}

        {view == "admin" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              الأدمن
            </FormLabel>
            <Search
              page={page}
              itemsCount={admins.data?.itemsCount}
              data={admins.data?.data}
              prop="name"
              currentElement={getValues("admin")}
              handleChangePage={(page) => {
                setPage(page);
              }}
              handleChangeQuery={(query) => {
                setQuery(query);
              }}
              handleSelected={(item) => setValue("admin", item)}
            />
          </FormControl>
        )}
        {view == "rates" && (
          <FormControl>
            <FormLabel fontWeight="bold" textTransform="capitalize">
              حالة الاوردر
            </FormLabel>
            <Select bg={theme.bg} color={theme.dark} {...register("status")}>
              <option style={{ color: theme.dark }} value="">
                {t("general.select")}
              </option>
              {[
                { preparing: "جار التحضيير" },
                { shipped: "في الشحن" },
                { available: "تم التوصيل" },
                { collected: "تم التحصيل" },
                { skip: "جار الاسترجاع" },
                { holding: "مؤجل" },
                { ask_to_return: "طلب العميل الإسترجاع" },
                { returned1: "مرتجع" },
                { returned2: "مرتجع بعد التسليم" },
                { declined1: "ملغي" },
                { declined2: "ملغية بعد التحضير" },
                { cancelled: "معلومات غير صحيحة" },
              ].map((item) => {
                const key = Object.keys(item)[0];
                return (
                  <option key={key} style={{ color: theme.dark }} value={key}>
                    {item[key]}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        )}

        <Flex alignItems="flex-end">
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="blue"
            me={2}
          >
            فلترة
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              setValue("from", "");
              setValue("to", "");
              setValue("filterType", "day");
              setValue("department", "All");
              setValue("admin", "");
              setValue("status", "");
              setFilterType("day"); // Reset the filterType state
            }}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
};

export default React.memo(Filter);
