import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Box,
  Divider,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";
import { logout } from "../../../store/auth/authSlice";
import systemBg from "../../../assets/images/logo-dark.png";
import { NavbarWrapper } from "./NavbarStyle";
import theme from "../../global/theme";
import NotificationsDrawer from "../../modules/notifications/NotificationsDrawer";
import CartDrawer from "../../modules/cart/CartDrawer";
import AlertDrawer from "../../modules/announcements/AlertDrawer";
import { AiTwotoneAlert } from "react-icons/ai";

import { MdFavoriteBorder } from "react-icons/md";
import BottomBar from "./BottomBar";

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const sidebar = useSelector((state) => state.sidebar);
  const notifications = useSelector((state) => state.notifications);
  const alerts = useSelector((state) => state.alerts);

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCartOpen,
    onOpen: onCartOpen,
    onClose: onCartClose,
  } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/available_products?query=${searchQuery}&page=1`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Use Chakra's responsive utilities
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <NavbarWrapper
      className={`${
        sidebar.isNotOpened ? "active" : ""
      } flex items-center justify-between`}
    >
      <div className="flex items-center">
        <Button
          p={0}
          bg="transparent"
          color={theme.dark}
          _hover={{ bg: "transparent", color: theme.primary }}
          _focus={{ outline: "none" }}
          onClick={() => dispatch(toggleSidebar())}
          className="mr-4"
        >
          <FiIcons.FiAlignRight size={25} />
          <Image w="70px" src={systemBg} />
        </Button>
      </div>

      <div className="flex items-center">
        {/* Search Input for Medium and Larger Screens */}
        <div className="items-center hidden md:flex">
          <InputGroup className="mr-4 transition-all duration-300">
            <Input
              placeholder="بحث..."
              className="w-20 duration-300 ease-in-out md:focus:w-72 transition-width"
              onFocus={(e) => e.target.parentNode.classList.add("w-20 md:w-40")}
              onBlur={(e) =>
                e.target.parentNode.classList.remove("w-20 md:w-40")
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <InputRightElement>
              <Button
                p={0}
                bg="transparent"
                _hover={{ bg: "transparent" }}
                onClick={handleSearch}
              >
                <FiIcons.FiSearch className="w-5 h-5 md:h-8 md:w-8" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>

        {/* Search Input for Small Screens */}
        <div className="flex sm:hidden">
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              color={theme.dark}
              _hover={{ color: theme.primary }}
              _focus={{ outline: "none" }}
            >
              <Button
                p={0}
                bg="transparent"
                _hover={{ bg: "transparent" }}
                onClick={handleSearch}
                className="mb-1"
              >
                <FiIcons.FiSearch className="w-5 h-5 md:h-8 md:w-8" />
              </Button>
            </MenuButton>
            <MenuList className="min-w-[180px] shadow-lg">
              <Input
                type="text"
                placeholder="بحث..."
                className="absolute w-full duration-300 ease-in-out"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </MenuList>
          </Menu>
        </div>

        {/* Favorite Products (conditionally hidden on small screens) */}
        {!isSmallScreen && (
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              color={theme.dark}
              onClick={() => navigate("/favorite_products")}
              _hover={{ color: theme.primary }}
              _focus={{ outline: "none" }}
              className="mr-3"
            >
              <MdFavoriteBorder className="w-5 h-5 md:h-8 md:w-8" />
            </MenuButton>
          </Menu>
        )}

        {/* Cart (conditionally hidden on small screens) */}
        {!isSmallScreen && (
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              color={theme.dark}
              onClick={onCartOpen}
              _hover={{ color: theme.primary }}
              _focus={{ outline: "none" }}
              className="mr-3"
            >
              <div className="relative flex items-center">
                <FiIcons.FiShoppingCart className="w-5 h-5 md:h-8 md:w-8" />
                <Box className="absolute top-[-16px] right-[13px] flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full">
                  {cart.items.length}
                </Box>
              </div>
            </MenuButton>
          </Menu>
        )}

        {/* Notifications */}
        <Button
          bg="transparent"
          color={theme.dark}
          onClick={onOpen}
          _hover={{ color: theme.primary }}
          _focus={{ outline: "none" }}
          className="mr-3 md:hidden"
        >
          <div className="relative flex items-center">
            <FiIcons.FiBell className="w-5 h-5 md:h-8 md:w-8" />
            <Box className="absolute top-[-16px] right-[13px] flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full">
              {notifications.data?.isNotRead}
            </Box>
          </div>
        </Button>
        {auth.user?.role == "marketer" && (
          <Button
            bg="transparent"
            color={theme.dark}
            onClick={onAlertOpen}
            _hover={{ color: theme.primary }}
            _focus={{ outline: "none" }}
            className="mr-3 md:hidden"
          >
            <div className="relative flex items-center">
              <AiTwotoneAlert
                style={{
                  animation: "pulse 2s infinite",
                  transformOrigin: "center",
                }}
                className="w-5 h-5 md:h-8 md:w-8"
              />
              <Box className="absolute top-[-16px] right-[13px] flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full">
                {alerts.data?.isNotRead}
              </Box>
            </div>
          </Button>
        )}

        {/* User Profile Menu */}
        <Menu>
          <MenuButton
            as={Button}
            bg="transparent"
            color={theme.dark}
            _hover={{ color: theme.primary }}
            _focus={{ outline: "none" }}
          >
            <BsPersonCircle className="w-5 h-5 md:h-8 md:w-8" />
          </MenuButton>
          <MenuList className="min-w-[180px] shadow-lg">
            <Box p={4}>
              <Box>
                <Text color={theme.dark} className="capitalize">
                  {auth.user?.name && auth.user.name.slice(0, 10)}
                </Text>
                <Text color={theme.secColor}>
                  {auth.user?.role}: <b>{auth.user?.code}</b>
                </Text>
              </Box>
              <Divider my={2} />
              <Flex
                as={Link}
                to="/profile"
                alignItems="center"
                color={theme.dark}
                className="capitalize hover:text-primary"
              >
                <FiIcons.FiUser />
                <Text ml={2}>{t("layout.navbar.profile")}</Text>
              </Flex>

              <Divider my={2} />
              <Flex
                as={Link}
                to="/"
                alignItems="center"
                color={theme.dark}
                className="capitalize hover:text-primary"
                onClick={() => dispatch(logout())}
              >
                <FiIcons.FiLogOut />
                <Text ml={2}>{t("layout.navbar.logout")}</Text>
              </Flex>
            </Box>
          </MenuList>
        </Menu>
      </div>

      {/* Include the NotificationsDrawer component */}
      <NotificationsDrawer isOpen={isOpen} onClose={onClose} />
      <CartDrawer isOpen={isCartOpen} onClose={onCartClose} cart={cart} />
      <AlertDrawer isOpen={isAlertOpen} onClose={onAlertClose} />

      <BottomBar />
    </NavbarWrapper>
  );
};

export default Navbar;
