import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { resetPassword } from "../../../store/auth/authSlice";

// import brandImage from "../../../assets/images/logo.png";
// import authBg from "../../../assets/images/auth-bg.jpg";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    dispatch(resetPassword(values))
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-center bg-cover"
      // style={{ backgroundImage: `url(${authBg})` }}
    >
      <div className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg">
        {/* Logo */}
        <div className="mb-6 text-center">
          {/* <img src={brandImage} alt="Logo" className="h-20 mx-auto mb-4" /> */}
          <h2 className="text-2xl font-semibold text-white">
            {t("pages.auth.reset_password")}
          </h2>
          <p className="mt-2 text-sm text-gray-400">
            {t("pages.auth.welcome_4")}
          </p>
        </div>

        <hr className="my-6 border-gray-600" />

        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block mb-1 text-sm text-white">
              {t("pages.auth.email")}
            </label>
            <input
              type="email"
              className="w-full p-3 text-white placeholder-gray-400 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={t("pages.auth.email")}
              {...register("email", {
                required: `${t("validation.required")}`,
              })}
            />
            {errors.email && (
              <p className="mt-1 text-xs text-red-500">
                {errors.email.message}
              </p>
            )}
          </div>

          <button
            type="submit"
            className={`w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-500 transition-all ${
              auth.isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {t("pages.auth.reset")}
          </button>

          {/* Link to login */}
          <div className="mt-4 text-center">
            <Link
              to="/login"
              className="text-sm text-gray-400 transition-colors hover:text-blue-500"
            >
              {t("pages.auth.have_an_account")}{" "}
              <span className="text-blue-500">{t("pages.auth.login")}</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
