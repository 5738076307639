import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";

const OrderProducts = ({ data, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={Boolean(data)} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="lg" fontWeight="bold" textAlign="center" w="100%">
              المنتجات
            </Text>
            <ModalCloseButton />
          </Flex>
        </ModalHeader>
        <ModalBody className="scrollable-modal-body">
          {data.items?.map((item, idx) => (
            <Text
              key={idx}
              fontSize="md"
              mb={2}
              p={2}
              bg="gray.50"
              borderRadius="md"
              boxShadow="sm"
            >
              <strong>({item.qty})</strong> قطعة {item.product?.name} -{" "}
              <strong>
                {
                  item.product.properties.find((it) => it._id === item.property)
                    ?.key
                }
              </strong>
              {data.items?.length !== idx + 1 && (
                <span style={{ margin: "0 8px" }}> // </span>
              )}
            </Text>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            {t("general.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderProducts;
