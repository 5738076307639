import React, { useState } from "react";
import {
  IconButton,
  Box,
  Text,
  VStack,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { FaWhatsapp, FaUsers, FaBoxOpen } from "react-icons/fa";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

// Motion components for animation
const MotionDiv = motion.div; // For making the whole container draggable
const MotionVStack = motion(VStack);

const SupportButton = () => {
  const [showOptions, setShowOptions] = useState(false); // Always show options now
  const supports = useSelector((state) => state.supports);

  return (
    <MotionDiv
      drag="x" // Allow horizontal dragging
      dragConstraints={{
        left: -window.innerWidth + 100, // Prevent dragging off the left side
        right: 0, // Prevent dragging off the right side
      }}
      style={{
        position: "fixed",
        zIndex: 9999,
        bottom: "30px",
        right: "20px",
      }}
    >
      {/* WhatsApp button */}
      <motion.div
        style={{
          zIndex: 9999,
          bottom: "30px",
        }}
      >
        <IconButton
          icon={<FaWhatsapp />}
          aria-label="Support"
          color="white"
          size="lg"
          variant="solid"
          bg="linear-gradient(45deg, #25d366, #128c7e)" // Gradient background
          _hover={{
            bg: "linear-gradient(45deg, #128c7e, #25d366)", // Swap gradient on hover
          }}
          _active={{
            bg: "linear-gradient(45deg, #1c6e56, #0b5e46)", // Darker gradient on active
          }}
          borderRadius="full"
          onClick={() => setShowOptions(!showOptions)}
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)"
          className="text-3xl"
        />
      </motion.div>

      {/* Options list */}
      {showOptions && (
        <MotionVStack
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          align="start"
          spacing={5}
          bg="white"
          p={5}
          boxShadow="lg"
          borderRadius="lg"
          position="absolute"
          bottom="50px" // Position it above the button
          right="0"
          width="200px" // Fixed width for the options container
        >
          {supports.data?.data?.map((el) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={4}
                _hover={{
                  bg: "#f1f1f1",
                  borderRadius: "lg",
                  cursor: "pointer",
                }}
                p={3}
                transition="all 0.3s ease-in-out"
              >
                <FaUsers size={20} className="text-green-500" />
                <Text fontSize={"sm"} fontWeight="bold">
                  <Link
                    href={`https://wa.me/2${el.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-500"
                    _hover={{
                      textDecoration: "underline",
                    }}
                  >
                    {el?.description}
                  </Link>
                </Text>
              </Box>
            );
          })}

          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            _hover={{
              bg: "#f1f1f1",
              borderRadius: "lg",
              cursor: "pointer",
            }}
            p={3}
            transition="all 0.3s ease-in-out"
          >
            <FaBoxOpen size={20} className="text-green-500" />
            <Text fontSize="lg" fontWeight="bold">
              <Link
                href="https://wa.me/201000258086"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500"
                _hover={{
                  textDecoration: "underline",
                }}
              >
                دعم الجملة
              </Link>
            </Text>
          </Box> */}
        </MotionVStack>
      )}
    </MotionDiv>
  );
};

export default SupportButton;
