import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";
import { updateAccount } from "../../../../store/accounts/accountsSlice";

const UpdateAccount = ({ data, onClose }) => {
  const { t } = useTranslation();
  const accounts = useSelector((state) => state.accounts);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const toast = useToast();
  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            dispatch(
              updateAccount({
                id: values._id,
                pending: parseInt(values.pending),
                preparing: parseInt(values.preparing),
                shipped: parseInt(values.shipped),
                available: parseInt(values.available),
              })
            )
              .unwrap()
              .then((res) => {
                toast({
                  description: "تم تعديل الحساب  بنجاح",
                });
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            تعديل أرصدة الحساب
          </ModalHeader>
          <ModalBody>
            {accounts.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {accounts.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  الرصيد المعلق
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="الرصيد المعلق"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("pending", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.pending?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.pending.message}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  الرصيد جار التحضير
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="الرصيد جار التحضير"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("preparing", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.preparing?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.preparing.message}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  رصيد في الشحن
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="الرصيد في الشحن"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("shipped", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.shipped?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.shipped.message}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  رصيد تم التوصيل
                </FormLabel>
                <Input
                  type="number"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="الرصيد تم التوصيل"
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("available", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.available?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.available.message}
                  </Text>
                )}
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={accounts.isLoading}
                _hover={{ background: "green" }}
              >
                تأكيد التعديل
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={accounts.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateAccount;
